import { createSlice, AnyAction } from '@reduxjs/toolkit';
import { CompanyRo, LoginRo, UserLoginRo } from '../services/api.generated';
import { api } from '../services/api';

type authSliceType = {
  user: UserLoginRo | null;
  token: LoginRo['token'];
  isAdmin: boolean;
  isManager: boolean;
  isActive: boolean;
  company: CompanyRo | null;
};

const isAdmin = (user: UserLoginRo) =>
  !!user.roleNames.find((item) => item.toLocaleLowerCase() === 'admin');

const isManager = (user: UserLoginRo) => !!user.isManager;

const isActive = (user: UserLoginRo) => !!user.isActive;

const initialState = () => {
  const localStorageUserInfo = localStorage.getItem('user');
  const localStorageUserToken = localStorage.getItem('token');
  let result: authSliceType = {
    user: null,
    token: '',
    isAdmin: false,
    isManager: false,
    isActive: true,
    company: null,
  };
  if (localStorageUserInfo && localStorageUserToken) {
    const userLocalInfo: UserLoginRo = JSON.parse(localStorageUserInfo);
    const userLocalToken: LoginRo['token'] = localStorageUserToken;
    result = {
      user: userLocalInfo,
      token: userLocalToken,
      isAdmin: isAdmin(userLocalInfo),
      isManager: isManager(userLocalInfo),
      isActive: isActive(userLocalInfo),
      company: null,
    };
  }
  return result;
};

const isAuthenticated = (action: AnyAction) => {
  return (
    api.endpoints.authControllerLogin.matchFulfilled(action) ||
    api.endpoints.authControllerSignup.matchFulfilled(action)
  );
};

const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout: () => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      return initialState();
    },
  },
  extraReducers: ({ addMatcher }) => {
    addMatcher(isAuthenticated, (state, { payload }: { payload: LoginRo }) => {
      const { token, user } = payload;
      state.user = user;
      state.token = token;
      state.isAdmin = !!user.roleNames.find(
        (item) => item.toLocaleLowerCase() === 'admin'
      );
      state.isManager = !!user.isManager;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
    });
    addMatcher(
      api.endpoints.usersControllerFindCompanyUsers.matchFulfilled,
      (state, { payload }) => {
        state.company = payload[0].company || null;
      }
    );
    addMatcher(
      api.endpoints.usersControllerUpdateUserProfile.matchFulfilled,
      (state, { meta }) => {
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...state.user,
            ...meta?.arg?.originalArgs?.userUpdateDto,
          })
        );
        state.user = {
          ...state.user!,
          ...meta?.arg?.originalArgs?.userUpdateDto,
        };
      }
    );
  },
});

export const { logout: logoutAction } = authSlice.actions;

export default authSlice.reducer;
