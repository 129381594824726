import type { Dispatch, FC, MouseEventHandler, SetStateAction } from 'react';
import { Fragment, useMemo, useState } from 'react';
import {
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Popover,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  Checkbox,
  Box,
  Tooltip,
} from '@mui/material';
import {
  TaskRo,
  useTasksControllerRemoveMutation,
  useTasksControllerDeleteMutation,
  useTasksControllerGenerateOutputMutation,
} from 'src/app/services/api.generated';
import { useTranslation } from 'react-i18next';
import { Warning, Error, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { taskGetComparator } from 'src/utils/sortTasksUtils';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import FilesSvg from 'src/components/atoms/svg/FilesSvg';
import moment from 'moment';
import { TaskTableSkeleton } from './TaskTableSkeleton';
import { toast } from 'react-toastify';
import { ConfirmDialog } from 'src/components/molecules/ConfirmDialog';
import { LoadingButton } from '@mui/lab';
import { taskStateConvertor } from 'src/utils/taskStateConvertorUtils';
import { SyncSvg } from 'src/components/atoms/svg/SyncSvg';
import LoadingSvg from 'src/components/atoms/svg/LoadingSvg';
import TickSvg from 'src/components/atoms/svg/TickSvg';
import { useNavigate } from 'react-router';
import { tableOrderType } from 'src/types/generalTypes';

type TasksTableBodyPropsType = {
  rows: TaskRo[];
  order: tableOrderType;
  orderBy: keyof TaskRo;
  page: number;
  rowsPerPage: number;
  isLoading: boolean;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  showCheckbox: boolean;
};

export const TasksTableBody: FC<TasksTableBodyPropsType> = ({
  rows,
  order,
  orderBy,
  page,
  rowsPerPage,
  isLoading,
  selected,
  setSelected,
  showCheckbox,
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedRow, setSelectedRow] = useState<TaskRo | null>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isAdmin, isManager } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    isManager: state.auth.isManager,
  }));

  const isOutputGenerated = useMemo(
    () => selectedRow?.state === 'READY' || false,
    [selectedRow]
  );

  const [userRemoveTask, { isLoading: userRemoveTaskLoading }] =
    useTasksControllerRemoveMutation();
  const [adminDeleteTask, { isLoading: adminDeleteTaskLoading }] =
    useTasksControllerDeleteMutation();
  const [generateOutput, { isLoading: generateOutputLoading }] =
    useTasksControllerGenerateOutputMutation();

  const loadingRenderIndicator = (item: string) => {
    let result = false;
    switch (item) {
      case 'remove':
        result = userRemoveTaskLoading || adminDeleteTaskLoading;
        break;
      case 'download':
        result = generateOutputLoading;
        break;
      default:
        break;
    }
    return result;
  };

  const openConfirmDialog = () => setShowConfirmDialog(true);
  const closeConfirmDialog = () => setShowConfirmDialog(false);

  const closePopover = () => setAnchorEl(null);
  const openPopover = ({
    taskRow,
    anchor,
  }: {
    taskRow: TaskRo;
    anchor: HTMLButtonElement;
  }) => {
    setSelectedRow(taskRow);
    setAnchorEl(anchor);
  };
  const popoverWrapperOnClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const isSelected = (id: string) => selected.includes(id);

  const checkboxOnChange = (id: string) => {
    setSelected((prevState) => {
      let result: string[] = [];
      if (prevState.includes(id)) {
        result = prevState.filter((item) => item !== id);
      } else {
        result = [...prevState, id];
      }
      return result;
    });
  };

  const removeTaskHandler = () => {
    if (!selectedRow) return;
    (isAdmin
      ? adminDeleteTask({ idDto: { id: selectedRow.id } })
      : userRemoveTask({ idDto: { id: selectedRow.id } })
    )
      .unwrap()
      .then(() => {
        toast.success(t('taskSuccessfullyDeleted'));
        closeConfirmDialog();
      });
  };

  const downloadFolderHandler = () => {
    if (!selectedRow) return;
    if (isOutputGenerated) {
      generateOutput({ idDto: { id: selectedRow.id } })
        .unwrap()
        .then(() => {
          toast.info(t('generateOutputReq'));
        });
    } else {
    }
  };

  const popoverItemOnClick = (itemName: string) => {
  switch (itemName) {
    case 'remove':
      openConfirmDialog();
      break;
    case 'download':
    case 'generateOutput':
      downloadFolderHandler();
      outputStateRenderHandler("GENERATING_OUTPUT");
      break;
  
    default:
      break;
  }
};

  const stateRenderHandler = (state: TaskRo['state']) => {
  let result = <></>;

  switch (state) {
    // Voeg hier cases toe voor elke staat in 'state'
    case 'ERROR':
      result = <Error color="error" />;
      break;
    case 'PREPARING':
    case 'CREATE_IMAGES':
    case 'CREATING_IMAGES':
    case 'PENDING':
    case 'PARTIAL_READY':
      result = <LoadingSvg />;
      break;
    case 'READY':
      result = (
        <>
          <TickSvg
            fontSize="small"
            sx={{ '&>path': { fill: ({ palette }) => palette.primary.main } }}
          />
        </>
      );
      break;
    default:
      break;
  }

  return result;
};

  const outputStateRenderHandler = (outputState: TaskRo['outputState']) => {
  let result = <></>;

  switch (outputState) {
    case 'GENERATING_OUTPUT':
      result = <SyncSvg />;
      break;
    case 'NOT_GENERATED':
      result = <Warning color="warning" />;
      break;
    case 'GENERATE_OUTPUT':
      result = <LoadingSvg />;
      break;
    case 'GENERATED_OUTPUT':
      result = (
        <TickSvg
          fontSize="small"
          sx={{ '&>path': { fill: ({ palette }) => palette.primary.main } }}
        />
      );
      break;
    default:
      break;
  }

  return result;
};

  const rowOnClick = (taskRow: TaskRo) => {
    navigate('/edit/' + taskRow.id);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const popoverOpenState = Boolean(anchorEl);
  const popoverId = popoverOpenState ? 'task menu' : undefined;

  return (
    <>
      <TableBody
        sx={{
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
        }}
      >
        {isLoading ? (
          <TaskTableSkeleton
            showCheckbox={showCheckbox}
            rowCount={rowsPerPage}
          />
        ) : (
          [...rows]
            .sort(taskGetComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: TaskRo, index: number) => {
              const { id, creator, name, state, createdAt } = row;
              const isItemSelected = isSelected(id);
              const realIndex = page * rowsPerPage + index;
              const labelId = `enhanced-table-checkbox-${realIndex}`;

              return (
                <Fragment key={realIndex}>
                  <TableRow
                    hover
                    tabIndex={-1}
                    sx={{
                      bgcolor: 'customGrey.dark',
                      cursor: row.state === 'READY' ? 'pointer' : 'default',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (row.state === 'READY') {
                        rowOnClick(row);
                      }
                    }}
                  >
                    {showCheckbox && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            checkboxOnChange(id);
                          }}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                    )}
                    <TableCell align="left" id={labelId}>
                      <Tooltip title={name}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          columnGap={2}
                        >
                          <FilesSvg />
                          <Typography
                            maxWidth={200}
                            textOverflow="ellipsis"
                            noWrap
                          >
                            {name}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{creator}</TableCell>
                    <TableCell align="center">
                      <Tooltip title={moment(createdAt).format('LLLL')}>
                        <Typography>
                          {moment(createdAt).format('YYYY/MM/DD')}
                        </Typography>
                      </Tooltip>
                    </TableCell>
		    <TableCell align="center">
                      {stateRenderHandler(state)}
                    </TableCell>
                    <TableCell align="center">
		      {outputStateRenderHandler(row.outputState)}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        aria-describedby="more"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          openPopover({
                            taskRow: row,
                            anchor: e.currentTarget,
                          });
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Box onClick={popoverWrapperOnClick}>
                        <Popover
                          elevation={1}
                          id={popoverId}
                          open={popoverOpenState && selectedRow?.id === id}
                          anchorEl={anchorEl}
                          onClose={closePopover}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          PaperProps={{
                            sx: {
                              backgroundColor: 'customGrey.light',
                            },
                          }}
                        >
                          <List>
  			{[
   			 'remove',
   			 `${isOutputGenerated ? 'download' : 'generateOutput'}`,
 			 ].map((item, index) => (
  			  <ListItemButton
   			  	 key={index}
   			   	component={LoadingButton}
   			  	 loading={loadingRenderIndicator(item)}
   			         onClick={() => popoverItemOnClick(item)}
   			         sx={{ width: '100%' }}
   			 	>
                                <ListItemText
                                  sx={{ textAlign: 'center' }}
                                  primary={t(item)}
                                />
                              </ListItemButton>
                            ))}
                          </List>
                        </Popover>
                      </Box>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })
        )}
        {emptyRows > 0 &&
          [...Array(emptyRows)].map((_, index) => (
            <TableRow key={index}>
              <TableCell colSpan={showCheckbox ? 5 : 6} sx={{ py: 4.5 }} />
            </TableRow>
          ))}
      </TableBody>
      <ConfirmDialog
        dialogProps={{
          open: showConfirmDialog,
          onClose: closeConfirmDialog,
        }}
        warningText={
          isAdmin || isManager
            ? t('confirmDeleteTask', {
                taskName: selectedRow !== null ? selectedRow.name : '',
                workspaceName:
                  selectedRow !== null ? selectedRow.workspace?.name : '',
              })
            : t('confirmRemoveTask', {
                taskName: selectedRow !== null ? selectedRow.name : '',
                workspaceName:
                  selectedRow !== null ? selectedRow.workspace?.name : '',
              })
        }
        onSubmitClick={removeTaskHandler}
        isSubmitLoading={loadingRenderIndicator('remove')}
      />
    </>
  );
};
