import { FC } from 'react';
import { Paper, Stack, Typography } from '@mui/material';

type EmptyListPropsType = {
  text?: any;
};

export const EmptyList: FC<EmptyListPropsType> = ({ text }) => {
  return (
    <Paper
      sx={{
        width: '100%',
        p: { xs: 5, sm: 7, md: 8.5, lg: 10.5 },
      }}
      component={Stack}
      rowGap={2}
      alignItems="center"
      justifyContent="center"
    >
      <img
        src={process.env.PUBLIC_URL + '/icons/empty-list.png'}
        alt="list is empty"
      />
      {text && <Typography align="center">{text}</Typography>}
    </Paper>
  );
};
