import { FC, MouseEvent, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import type { DialogProps } from '@mui/material';
import { useUsersControllerMailResetPasswordMutation } from 'src/app/services/api.generated';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';

// const isDevelopment = process.env.NODE_ENV === 'development',
//   baseUrl = isDevelopment
//     ? (process.env.REACT_APP_DEVELOPMENT_BASE_URL as string)
//     : (process.env.REACT_APP_PRODUCTION_BASE_URL as string);

const SendMail: FC<DialogProps> = (props) => {
  const { t } = useTranslation(),
    navigate = useNavigate();

  const [email, setEmail] = useState('');

  const [sendMail, { isLoading }] =
    useUsersControllerMailResetPasswordMutation();

  const sendMailHandler = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    if (!email) return;
    sendMail({
      mailResetPasswordDto: {
        email,
        callbackUrl: window.location.origin + '/forgotPassword/',
      },
    })
      .unwrap()
      .then(() => {
        toast.success(t('sendMail.toast'));
        props.onClose && props.onClose(e, 'escapeKeyDown');
        navigate('/login');
      });
  };

  return (
    <Dialog {...props} PaperProps={{ sx: { p: { xs: 0, sm: 2 } } }}>
      <DialogTitle align="center">{t('sendMail.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText lineHeight="20px" align="center" sx={{ mb: 6 }}>
          {t('sendMail.subTitle')}
        </DialogContentText>
        <TextField
          size="small"
          fullWidth
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          label={t('email')}
          type="email"
          placeholder="mail@example.com"
          inputProps={{
            enterKeyHint: 'send',
            autoComplete: 'on',
            name: 'email',
          }}
        />
      </DialogContent>
      <DialogActions>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="center"
          columnGap={4}
          rowGap={2}
          sx={{ width: '100%', p: 2 }}
        >
          <Button
            size="large"
            fullWidth
            variant="outlined"
            color="primary"
            onClick={(e) => props.onClose && props.onClose(e, 'escapeKeyDown')}
          >
            {t('cancel')}
          </Button>
          <LoadingButton
            loading={isLoading}
            size="large"
            fullWidth
            variant="contained"
            onClick={(e) => sendMailHandler(e)}
            disabled={!email}
          >
            {t('sendEmail')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default SendMail;
