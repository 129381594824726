import { FC, useState, MouseEvent, useMemo, useEffect } from 'react';
import {
  Badge,
  Box,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { NotifSvg } from 'src/components/atoms/svg/NotifSvg';
import {
  useNotificationControllerGetUnseenNotificationCountQuery,
  useNotificationControllerGetPreviewUnseenNotificationQuery,
} from 'src/app/services/api.generated';
import {
  NotificationCenterRow,
  NotificationCenterRowSkeleton,
} from './NotificationCenterRow';
import { useNavigate } from 'react-router';
import LaunchIcon from '@mui/icons-material/Launch';

// ! INTERVAL DURATION
const intervalDuration = 60000;

export const NotificationCenter: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToNotificationsPage = () => navigate('/notifications');

  const {
    data: unseenNotifications = [],
    isLoading: getUnseenNotifLoading,
    refetch: refetchUnseenNotif,
  } = useNotificationControllerGetPreviewUnseenNotificationQuery({} as any, {
    skip: !anchorEl,
  });

  const { data: unseenNotifCount, refetch: unseenNotifCountRefetch } =
    useNotificationControllerGetUnseenNotificationCountQuery();

  // ! FETCHING UNSEEN NOTIF COUNT INTERVAL *//
  useEffect(() => {
    if (anchorEl) return;
    const getunseenNotifCountInterval = setInterval(() => {
      unseenNotifCountRefetch();
    }, intervalDuration);
    return () => clearInterval(getunseenNotifCountInterval);
  }, [anchorEl, unseenNotifCountRefetch]);

  // ! FETCHING UNSEEN NOTIF LIST INTERVAL *//
  useEffect(() => {
    if (!anchorEl) return;
    const getUnseenNotifListInterval = setInterval(() => {
      refetchUnseenNotif();
    }, intervalDuration);
    return () => clearInterval(getUnseenNotifListInterval);
  }, [anchorEl, refetchUnseenNotif]);

  const hasNewNotif = useMemo(
    () => (unseenNotifCount ? unseenNotifCount.notificationCount > 0 : false),
    [unseenNotifCount]
  );

  const openPopoverHandler = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const closePopoverHandler = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'notificationPopover' : undefined;

  return (
    <>
      <IconButton onClick={openPopoverHandler} aria-label="notification menu">
        <Badge
          color="primary"
          badgeContent={unseenNotifCount?.notificationCount}
          invisible={!hasNewNotif}
        >
          <NotifSvg sx={{ '&> path': { fill: 'rgba(256,256,256,0.5)' } }} />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopoverHandler}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'customGrey.light',
            px: { xs: 1, sm: 2 },
            pb: { xs: 1, sm: 2 },
            borderTopRightRadius: 0,
            width: 'clamp(280px, 100%, 357px)',
            position: 'relative',
          },
        }}
      >
        <Tooltip
          enterDelay={700}
          leaveDelay={250}
          title={t('showAllNotif')}
          sx={{
            position: 'absolute',
            top: { xs: 7, sm: 10 },
            right: { xs: 7, sm: 10 },
          }}
        >
          <IconButton onClick={goToNotificationsPage}>
            <LaunchIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Typography
          fontWeight={500}
          fontSize={17}
          align="center"
          sx={{ p: { xs: 1.5, sm: 2 }, borderBottom: '1px solid #aaa' }}
        >
          {t('notificationCenter')}
        </Typography>
        <Box sx={{ maxHeight: 400, overflow: 'overlay' }}>
          {!hasNewNotif ? (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', height: 200 }}
            >
              <Typography align="center" fontSize={13} color={grey[400]}>
                {t('noNotification')}
              </Typography>
            </Stack>
          ) : getUnseenNotifLoading ? (
            [...Array(3)].map((_, index) => (
              <NotificationCenterRowSkeleton key={index} />
            ))
          ) : (
            unseenNotifications.map((item) => (
              <NotificationCenterRow {...item} key={item.id} />
            ))
          )}
        </Box>
      </Popover>
    </>
  );
};
