import type { FC, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useMemo, useState } from 'react';
import { Toolbar, Button, Typography, Stack } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from 'src/components/molecules/ConfirmDialog';
import {
  TaskRo,
  useTasksControllerRemoveManyMutation,
} from 'src/app/services/api.generated';
import { TableSearchInput } from 'src/components/atoms/TableSearchInput';
import Grid2 from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';
import i18n from 'src/i18n';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

type TaskTableToolbarPropsType = {
  rows: TaskRo[];
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  searchInput: string;
  setSearchInput: Dispatch<SetStateAction<string>>;
  goToCreateTaskPage?: () => void;
};

export const TaskTableToolbar: FC<TaskTableToolbarPropsType> = ({
  rows,
  selected,
  setSelected,
  searchInput,
  setSearchInput,
  goToCreateTaskPage,
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const [deleteManyTasks, { isLoading: deleteManyTasksLoading }] =
    useTasksControllerRemoveManyMutation();

  const { isAdmin, isManager } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    isManager: state.auth.isManager,
  }));

  const { t } = useTranslation();
  const navigate = useNavigate();

  const openDialogHandler = () => setShowDialog(true);
  const closeDialogHandler = () => setShowDialog(false);

  const inputChangeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setSearchInput(event.target.value);

  const selectedTasksNameRenderHandler = () => {
    let result = '';
    selected.slice(0, 3).forEach((item) => {
      result = result + ` '${rows.find((row) => row.id === item)?.name}'`;
    });
    if (selected.length > 4) {
      if (i18n.language === 'nl') {
        result = result + ` en ${selected.length - 3} andere taken`;
      } else {
        result = result + ` and ${selected.length - 3} other tasks`;
      }
    }
    return result;
  };

  const isAdminOrManager = useMemo(
    () => isAdmin || isManager,
    [isAdmin, isManager]
  );

  const clearButtonOnClick = () => {
    setSelected([]);
  };
  const removeButtonOnClick = () => {
    if (selected.length === 0 || rows.length === 0 || isAdminOrManager) return;

    deleteManyTasks({ idsDto: { ids: selected } })
      .unwrap()
      .then(() => closeDialogHandler());
  };

  return (
    <>
      <Toolbar
        sx={{
          mb: 1,
          px: '0 !important',
          minWidth: '100%',
        }}
        component={Grid2}
        container
        rowGap={{ xs: 2, sm: 0 }}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={{
          xs: 'center',
          sm: 'space-between',
        }}
      >
        {selected.length === 0 ? (
          <>
            <TableSearchInput
              value={searchInput}
              onChange={inputChangeHandler}
            />
            <Button
              onClick={goToCreateTaskPage || (() => navigate('/createTasks'))}
              variant="contained"
              startIcon={<AddIcon />}
            >
              {t('newTask')}
            </Button>
          </>
        ) : (
          <>
            <Typography>
              {selected.length} {t('selectedItems')}
            </Typography>
            <Stack direction="row" alignItems="center" columnGap={2}>
              <Button
                variant="contained"
                color="inherit"
                sx={{ color: 'black' }}
                onClick={clearButtonOnClick}
              >
                {t('clear')}
              </Button>
              <LoadingButton
                disabled={isAdminOrManager}
                loading={deleteManyTasksLoading}
                onClick={openDialogHandler}
                color="error"
                variant="contained"
              >
                {t('remove')}
              </LoadingButton>
            </Stack>
          </>
        )}
      </Toolbar>
      {showDialog && (
        <ConfirmDialog
          title={t('deleteTask')}
          warningText={t('confirmRemoveManyTask', {
            taskName: selectedTasksNameRenderHandler(),
            workspaceName: rows[0].workspace?.name,
          })}
          dialogProps={{
            open: true,
            onClose: closeDialogHandler,
          }}
          onSubmitClick={removeButtonOnClick}
          submitText={t('removeSelectedItems')}
          isSubmitLoading={false}
        />
      )}
    </>
  );
};
