import { AxiosRequestConfig } from 'axios';
import {
  api as generatedApi,
  TasksControllerGetTasksStateApiArg,
  TasksControllerGetTasksStateApiResponse,
  MediaControllerUploadDocumentApiArg,
  MediaControllerUploadDocumentApiResponse,
} from './api.generated';

enum ApiTagsEnum {
  USER = 'USER',
  WORKSPACE = 'WORKSPACE',
  WORKSPACE_USER = 'WORKSPACE_USER',
  FOLDER = 'FOLDER',
  COMPANY = 'COMPANY',
  TASK = 'TASK',
  NOTIFICATION = 'NOTIFICATION',
  WORD = 'WORD',
}

type tagsType =
  | ApiTagsEnum
  | {
      type: ApiTagsEnum;
      id: string;
    };

type tagGeneratorType = <T>(props: {
  tag: ApiTagsEnum;
  list?: T;
  idKey?: string;
  callbackFunc?: (arg: T) => tagsType[];
}) => tagsType[];

const tagGenerator: tagGeneratorType = ({
  tag,
  list,
  idKey = 'id',
  callbackFunc,
}) => {
  if (!list || !Array.isArray(list)) {
    console.warn(
      `Please fix the result of 'tagGenerator' function in api.ts file.\n\n tag name is "${tag}"\n\n list: ${list}\n\n ATTENTION: 'tagGenerator' function just be used in case of the response be an array and has id in each item.\n in other cases you can get help from 'callbackFunc' to generate result`
    );
    return [];
  }
  if (callbackFunc) {
    return callbackFunc(list);
  }
  const tags = list.map((item) => ({
    type: tag,
    id: item[idKey],
  }));
  return [...tags, tag];
};

export const api = generatedApi
  .enhanceEndpoints({
    addTagTypes: Object.values(ApiTagsEnum),
    endpoints: {
      // ===================== NOTIFICATION ===================== //
      notificationControllerGetUnseenNotification: {
        providesTags: (response) =>
          tagGenerator({ list: response, tag: ApiTagsEnum.NOTIFICATION }),
      },
      notificationControllerGetPreviewUnseenNotification: {
        providesTags: (response) =>
          tagGenerator({ list: response, tag: ApiTagsEnum.NOTIFICATION }),
      },
      notificationControllerGetNotification: {
        providesTags: (response) =>
          tagGenerator({ list: response, tag: ApiTagsEnum.NOTIFICATION }),
      },
      notificationControllerGetUnseenNotificationCount: {
        providesTags: [
          {
            type: ApiTagsEnum.NOTIFICATION,
            id: 'unSeenNotifCount',
          },
        ],
      },
      notificationControllerGetAllNotification: {
        providesTags: [
          {
            type: ApiTagsEnum.NOTIFICATION,
            id: 'unSeenNotifCount',
          },
        ],
      },
      notificationControllerSeenNotification: {
        invalidatesTags: [ApiTagsEnum.NOTIFICATION],
      },
      notificationControllerCreateNotification: {
        invalidatesTags: [ApiTagsEnum.NOTIFICATION],
      },
      notificationControllerCreateMyCompanyNotification: {
        invalidatesTags: [ApiTagsEnum.NOTIFICATION],
      },
      notificationControllerCreateCompanyNotification: {
        invalidatesTags: [ApiTagsEnum.NOTIFICATION],
      },
      // ======================= WORKSPACE ======================= //
      workspacesControllerGetMyWorkspaces: {
        providesTags: (response) =>
          tagGenerator({
            list: response,
            tag: ApiTagsEnum.WORKSPACE,
          }),
      },
      workspacesControllerGetWorkspaceById: {
        providesTags: (response) =>
          response ? [{ type: ApiTagsEnum.WORKSPACE, id: response.id }] : [],
      },
      workspacesControllerGetCompanyWorkspaces: {
        providesTags: (response) =>
          tagGenerator({ list: response, tag: ApiTagsEnum.WORKSPACE }),
      },

      workspacesControllerCreateWorkspace: {
        invalidatesTags: [ApiTagsEnum.WORKSPACE],
      },
      workspacesControllerDeleteWorkspace: {
        invalidatesTags: [ApiTagsEnum.WORKSPACE],
      },
      workspacesControllerUpdateWorkspace: {
        invalidatesTags: [ApiTagsEnum.WORKSPACE],
      },
      // ======================= WORKSPACE_USER ======================= //
      workspacesControllerFindWorkspaceUser: {
        providesTags: [ApiTagsEnum.WORKSPACE_USER],
      },

      workspacesControllerInviteUserToWorkspace: {
        invalidatesTags: [ApiTagsEnum.WORKSPACE_USER, ApiTagsEnum.WORKSPACE],
      },
      workspacesControllerDeleteWorkspaceUser: {
        invalidatesTags: [ApiTagsEnum.WORKSPACE_USER, ApiTagsEnum.WORKSPACE],
      },
      workspacesControllerAssignWorkspaceRole: {
        invalidatesTags: [ApiTagsEnum.WORKSPACE_USER, ApiTagsEnum.WORKSPACE],
      },
      // ======================= FOLDER ======================= //
      workspacesControllerGetFolderById: {
        providesTags: (response) =>
          response
            ? [
                ...response.folders.map(({ id }) => ({
                  type: ApiTagsEnum.FOLDER,
                  id: id,
                })),
                ...response.tasks.map(({ id }) => ({
                  type: ApiTagsEnum.TASK,
                  id: id,
                })),
                ApiTagsEnum.FOLDER,
                ApiTagsEnum.TASK,
              ]
            : [ApiTagsEnum.FOLDER, ApiTagsEnum.TASK],
      },
      workspacesControllerGetRootFolder: {
        providesTags: (response) =>
          response
            ? [
                ...response.folders.map(({ id }) => ({
                  type: ApiTagsEnum.FOLDER,
                  id,
                })),
                ...response.tasks.map(({ id }) => ({
                  type: ApiTagsEnum.TASK,
                  id,
                })),
                ApiTagsEnum.FOLDER,
                ApiTagsEnum.TASK,
              ]
            : [ApiTagsEnum.FOLDER, ApiTagsEnum.TASK],
      },

      workspacesControllerCreateNewFolder: {
        invalidatesTags: [ApiTagsEnum.FOLDER],
      },
      workspacesControllerRemoveFolder: {
        invalidatesTags: [ApiTagsEnum.FOLDER],
      },
      workspacesControllerDeleteFolder: {
        invalidatesTags: [ApiTagsEnum.FOLDER],
      },
      workspacesControllerRenameFolder: {
        invalidatesTags: [ApiTagsEnum.FOLDER],
      },
      workspacesControllerAddFolderTo: {
        invalidatesTags: [ApiTagsEnum.FOLDER],
      },
      // ======================= COMPANIES ======================= //
      usersControllerGetCompanies: {
        providesTags: (response) =>
          tagGenerator({ list: response, tag: ApiTagsEnum.COMPANY }),
      },
      // ======================= USERS ======================= //
      usersControllerFind: {
        providesTags: (response) =>
          tagGenerator({ list: response, tag: ApiTagsEnum.USER }),
      },
      usersControllerFindCompanyUsers: {
        providesTags: (response) =>
          tagGenerator({ list: response, tag: ApiTagsEnum.USER }),
      },
      usersControllerGetUserProfile: {
        providesTags: (response) =>
          tagGenerator({
            list: response,
            tag: ApiTagsEnum.USER,
            idKey: 'username',
          }),
      },
      // TODO
      usersControllerCheckResetPasswordCred: {},
      usersControllerGetCompanyUsers: {
        providesTags: (response) =>
          tagGenerator({
            list: response,
            tag: ApiTagsEnum.USER,
            idKey: 'username',
          }),
      },

      usersControllerUpdate: {
        invalidatesTags: [ApiTagsEnum.COMPANY, ApiTagsEnum.USER],
      },
      usersControllerCreate: {
        invalidatesTags: [ApiTagsEnum.USER, ApiTagsEnum.COMPANY],
      },
      usersControllerCreateCompanyUser: {
        invalidatesTags: [ApiTagsEnum.USER, ApiTagsEnum.COMPANY],
      },
      usersControllerSuspend: {
        invalidatesTags: [ApiTagsEnum.USER],
      },
      usersControllerActivate: {
        invalidatesTags: [ApiTagsEnum.USER],
      },
      usersControllerAssignRole: {
        invalidatesTags: [ApiTagsEnum.USER],
      },
      usersControllerUpdateCompanyLicense: {
        invalidatesTags: (
          response,
          error,
          { updateCompanyLimitDto: { id } }
        ) => [{ type: ApiTagsEnum.COMPANY, id }],
      },
      usersControllerDelete: {
        invalidatesTags: [ApiTagsEnum.USER],
      },
      usersControllerUpdateUserProfile: {
        invalidatesTags: [ApiTagsEnum.USER],
      },
      usersControllerMailResetPassword: {
        invalidatesTags: [ApiTagsEnum.USER],
      },
      usersControllerResetPassword: {
        invalidatesTags: [ApiTagsEnum.USER],
      },
      usersControllerChangePassword: {
        invalidatesTags: [ApiTagsEnum.USER],
      },
      // ========================= TASK ========================= //
      tasksControllerGetTask: {
        providesTags: (response) =>
          tagGenerator({ list: response, tag: ApiTagsEnum.TASK }),
      },
      tasksControllerGetCompanyTasks: {
        providesTags: (response) =>
          tagGenerator({ list: response, tag: ApiTagsEnum.TASK }),
      },
      tasksControllerGetLastModifiedTasks: {
        providesTags: (response) =>
          tagGenerator({ list: response, tag: ApiTagsEnum.TASK }),
      },
      // TODO
      tasksControllerGetTasksState: {},
      tasksControllerGetTaskOutputState: {},
      tasksControllerGetTaskCurrentPage: {
        providesTags: (response) =>
          tagGenerator({ list: response, tag: ApiTagsEnum.TASK }),
      },
      tasksControllerGetTaskPagesUrl: {},
      tasksControllerGetTaskOutFileUrl: {},

      tasksControllerDelete: {
        invalidatesTags: (response, error, { idDto: { id } }) => [
          { type: ApiTagsEnum.TASK, id },
        ],
      },
      tasksControllerDeleteItem: {
        invalidatesTags: (response, error, { idDto: { id } }) => [
          { type: ApiTagsEnum.TASK, id },
        ],
      },
      tasksControllerRemoveMany: {
        invalidatesTags: (response, error, { idsDto: { ids } }) =>
          ids.map((id) => ({ type: ApiTagsEnum.TASK, id })),
      },
      mediaControllerUploadDocument: {
        invalidatesTags: () => [ApiTagsEnum.TASK],
      },
      tasksControllerRemove: {
        invalidatesTags: (response, error, { idDto: { id } }) => [
          { type: ApiTagsEnum.TASK, id },
        ],
      },
      tasksControllerDeleteCompanyTasks: {
        invalidatesTags: (response, error, { id }) => [
          { type: ApiTagsEnum.TASK, id },
        ],
      },
      tasksControllerAddItem: {},
      tasksControllerAddCustomBox: {},
      tasksControllerUpdateBox: {},
      tasksControllerChangeRedactState: {},
      tasksControllerChangeVerify: {},
      tasksControllerGenerateOutput: {},
      tasksControllerSetTaskCurrentPage: {},
      tasksControllerCreateLegalCode: {},
      tasksControllerRemoveLegalCode: {},
      tasksControllerRedactAll: {},
      tasksControllerUnRedactAll: {},
      tasksInternalControllerGetPdf2ImgTask: {},
      tasksInternalControllerSetPdf2ImgTaskResult: {},
      tasksInternalControllerGetGeneratingTasks: {},
      tasksInternalControllerSetGeneratingTasks: {},
      // ======================= LOGOUT ======================= //
      authControllerLogin: {
        invalidatesTags: Object.values(ApiTagsEnum),
      },
      // ======================= ROLE ======================= //
      rolesControllerReadPermissions: {},
      rolesControllerFindRole: {},
      rolesControllerReadRole: {},

      rolesControllerCreateRole: {},
      rolesControllerUpdateRole: {},
      rolesControllerDeleteRole: {},
      // ======================= WORD_TAGGER ======================= //
      wordTaggerControllerFindAll: {
        providesTags: (response) =>
          tagGenerator({ list: response, tag: ApiTagsEnum.WORD }),
      },
      wordTaggerControllerFindMyWords: {
        providesTags: (response) =>
          tagGenerator({ list: response, tag: ApiTagsEnum.WORD }),
      },
      wordTaggerControllerFindOne: {
        providesTags: (response) =>
          response ? [{ id: response.id, type: ApiTagsEnum.WORD }] : [],
      },

      wordTaggerControllerCreate: {
        invalidatesTags: () => [ApiTagsEnum.WORD],
      },
      wordTaggerControllerUpdate: {
        invalidatesTags: (response, error, { id }) => [
          { type: ApiTagsEnum.WORD, id },
        ],
      },
      wordTaggerControllerRemove: {
        invalidatesTags: () => [ApiTagsEnum.WORD],
      },
      usersControllerGetNotOwnerUsers: {
        providesTags: (response) => [ApiTagsEnum.COMPANY],
      },
      companiesControllerFindAll: {
        providesTags: (response) =>
          tagGenerator({
            list: response,
            tag: ApiTagsEnum.COMPANY,
          }),
      },
      companiesControllerCreate: {
        invalidatesTags: () => [ApiTagsEnum.COMPANY],
      },
      companiesControllerDelete: {
        invalidatesTags: () => [ApiTagsEnum.COMPANY],
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      tasksControllerGetTasksState_custom: build.mutation<
        TasksControllerGetTasksStateApiResponse,
        TasksControllerGetTasksStateApiArg
      >({
        query: (queryArg) => {
          let response = '/api/v1/tasks/tasks-state';
          queryArg.ids.forEach((id, index) => {
            if (index === 0) {
              response = response + `?ids=${id}`;
            } else {
              response = response + `&ids=${id}`;
            }
            return response;
          });

          return {
            url: response,
          };
        },
      }),
      customMediaControllerUploadDocument: build.mutation<
        MediaControllerUploadDocumentApiResponse,
        MediaControllerUploadDocumentApiArg & {
          abortController: AbortController;
          onUploadProgress: AxiosRequestConfig['onUploadProgress'];
        }
      >({
        query: ({ body, abortController, onUploadProgress }) => {
          const formData = new FormData();

          Object.keys(body).forEach((k) => {
            const key = k as keyof MediaControllerUploadDocumentApiArg['body'];
            let value = body[key];

            if (value === undefined || value === null) return;

            if (
              key === 'setting' ||
              key === 'haveLegalCode' ||
              key === 'pagesRanges'
            ) {
              value = JSON.stringify(value);
            }

            formData.append(key, value as string | Blob);
          });

          return {
            url: `/api/v1/media`,
            method: 'POST',
            body: formData,
            abortController,
            onUploadProgress,
          };
        },
      }),
    }),
  });

export const {
  useLazyTasksControllerGetTaskOutputStateQuery,
  useTasksControllerGetTasksState_customMutation,
  useLazyTasksControllerSearchQuery,
  useCustomMediaControllerUploadDocumentMutation,
} = api;
