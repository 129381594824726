import { FC } from 'react';
import { useNavigate } from 'react-router';
import { useUsersControllerCheckResetPasswordCredQuery } from 'src/app/services/api.generated';
import { ChangePassword } from 'src/components/organisms/changePassword/ChangePassword';
import LoginTemplate from 'src/components/templates/LoginTemplate';
import Loading from 'src/components/atoms/Loading';
import { Paper, useMediaQuery, useTheme, Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

export const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const resetCode = searchParams.get('resetCode');
  const email = searchParams.get('email');

  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const { isLoading, isError } = useUsersControllerCheckResetPasswordCredQuery(
    {
      resetCode: resetCode!,
      email: email!,
    },
    { skip: !resetCode || !email }
  );

  if (!resetCode || !email || isError) navigate(-1);

  return (
    <>
      {isLoading && <Loading />}
      <LoginTemplate>
        <Container
          maxWidth="xs"
          sx={{
            px: 0,
            borderRadius: 0,
            height: { xs: '100%', sm: 'auto' },
          }}
        >
          <Paper
            elevation={isMd ? 4 : 0}
            sx={{
              height: { xs: '100%', sm: 'auto' },
            }}
          >
            <ChangePassword
              resetCode={searchParams.get('resetCode')}
              email={searchParams.get('email') as string}
            />
          </Paper>
        </Container>
      </LoginTemplate>
    </>
  );
};
