import { FC } from 'react';
import {
  CircularProgress,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import FilesSvg from 'src/components/atoms/svg/FilesSvg';
import { Close } from '@mui/icons-material';
import {
  NotificationRo,
  useNotificationControllerSeenNotificationMutation,
} from 'src/app/services/api.generated';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { useAppDispatch } from 'src/app/reduxCustomHooks';
import { setSelectedNotificationAction } from 'src/app/slices/notificationSlice';

export const NotificationCenterRowSkeleton: FC = () => {
  return (
    <Stack
      direction="row"
      columnGap={1}
      sx={{
        p: 2,
        transition: '150ms',
        '&:hover': {
          bgcolor: ({ palette }) => palette.customGrey.dark,
        },
        width: '100%',
      }}
    >
      <FilesSvg />
      <Stack sx={{ width: '100%' }}>
        <Typography
          fontSize="14px"
          lineHeight="14px"
          sx={{ p: 1, pr: 5, width: '50%' }}
        >
          <Skeleton />
        </Typography>
        <Typography
          fontSize="12px"
          lineHeight="14px"
          sx={{ p: 1, pr: 5, width: '100%' }}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Typography>
      </Stack>
    </Stack>
  );
};

export const NotificationCenterRow: FC<NotificationRo> = (props) => {
  const { date, from, title, text, id } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [seenNotif, { isLoading }] =
    useNotificationControllerSeenNotificationMutation();

  const seenNotifHandler = () => seenNotif({ idsDto: { ids: [id] } });

  const rowOnClick = () => {
    seenNotifHandler();
    dispatch(setSelectedNotificationAction(id));
    navigate(`/notifications`);
  };

  return (
    <Stack
      onClick={rowOnClick}
      direction="row"
      columnGap={1}
      sx={{
        position: 'relative',
        p: 2,
        transition: '150ms',
        cursor: 'pointer',
        '&:hover': {
          bgcolor: ({ palette }) => palette.customGrey.dark,
          '&> .closeNotifIcon': {
            opacity: 1,
          },
        },
      }}
    >
      {isLoading ? (
        <CircularProgress
          size={15}
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            opacity: '0.5',
          }}
          color="inherit"
        />
      ) : (
        <IconButton
          className="closeNotifIcon"
          size="small"
          sx={{
            transition: '500ms',
            opacity: 0,
            position: 'absolute',
            right: '5px',
            top: '5px',
          }}
          onClick={seenNotifHandler}
        >
          <Close
            sx={{
              fontSize: '14px',
              color: 'white',
            }}
          />
        </IconButton>
      )}
      <FilesSvg
        sx={{
          mt: 4,
          '&> path': {
            fill: ({ palette }) => palette.primary.main,
          },
        }}
      />
      <Stack
        sx={{
          width: '100%',
          overflow: 'hidden',
          pl: 1,
          pr: 1.8,
          '&> p': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '100%',
          },
        }}
        rowGap={1.2}
      >
        <Typography fontSize="11px" sx={{ opacity: 0.5 }}>
          {from}
        </Typography>
        <Typography fontSize="14px" fontWeight={500}>
          {title}
        </Typography>
        <Typography
          fontSize="12px"
          fontWeight={300}
          sx={{ mt: -0.8 }}
          align="justify"
          lineHeight={1.2}
        >
          {text}
        </Typography>
        <Typography align="right" fontSize="9px" sx={{ opacity: 0.7 }}>
          {moment(date).format('YYYY/MM/DD')}
        </Typography>
      </Stack>
    </Stack>
  );
};
