import { FC, useMemo } from 'react';
import {
  Checkbox,
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import FilesSvg from 'src/components/atoms/svg/FilesSvg';

const smallBoxWidth = 5;

type TaskTableSkeletonPropsType = {
  rowCount?: number;
  cellCount?: number;
  showCheckbox?: boolean;
};

export const TaskTableSkeleton: FC<TaskTableSkeletonPropsType> = ({
  rowCount = 5,
  cellCount = 3,
  showCheckbox = true,
}) => {
  const largeBoxWidth = useMemo(() => 100 / cellCount, [cellCount]);

  const mediumBoxWidth = useMemo(
    () =>
      (100 - largeBoxWidth - smallBoxWidth * (showCheckbox ? 2 : 1)) /
      cellCount,
    [cellCount, largeBoxWidth, showCheckbox]
  );

  return (
    <>
      {[...Array(rowCount)].map((_, index) => (
        <TableRow key={index}>
          {showCheckbox && (
            <TableCell padding="checkbox" width={`${smallBoxWidth}%`}>
              <Checkbox color="primary" checked={false} disabled />
            </TableCell>
          )}
          <TableCell width={`${largeBoxWidth}%`}>
            <Stack direction="row" columnGap={2} alignItems="center">
              <FilesSvg />
              <Skeleton width="calc(100% - 50px)" />
            </Stack>
          </TableCell>
          {[...Array(cellCount)].map((_, index) => (
            <TableCell key={index} width={`${mediumBoxWidth}%`}>
              <Skeleton />
            </TableCell>
          ))}
          <TableCell width={`${smallBoxWidth}%`} align="center">
            <IconButton disabled>
              <MoreVert />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
