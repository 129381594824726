import { AnyAction, createSlice } from '@reduxjs/toolkit';
import type { ItemRo, TaskRo } from '../services/api.generated';
import { api } from '../services/api';
import { logoutAction } from './authSlice';

type editPageSliceType = {
  task: TaskRo | null;
  pages: ItemRo[][];
  openRightSidebar: boolean;
};

const localSettings = localStorage.getItem('appSettings');

const initialState: editPageSliceType = {
  task: null,
  pages: [],
  openRightSidebar: localSettings
    ? JSON.parse(localSettings).openRightSidebar
    : true,
};

const legalActionHandler = (action: AnyAction) => {
  return (
    api.endpoints.tasksControllerCreateLegalCode.matchFulfilled(action) ||
    api.endpoints.tasksControllerRemoveLegalCode.matchFulfilled(action)
  );
};

const fixRect = (rect: number[][]) => {
  const res = rect.sort((p1, p2) => (p1[0] - p1[1] >= p2[0] - p2[1] ? 1 : -1));
  const topLeft = res[0];
  const bottomRight = res[3];

  const res2 = rect.sort((p1, p2) => (p1[0] + p1[1] >= p2[0] + p2[1] ? 1 : -1));
  const topRight = res2[3];
  const bottomLeft = res2[0];

  return [topLeft, topRight, bottomLeft, bottomRight];
};

const fixItem = (item: ItemRo) => ({
  ...item,
  boxes: item.boxes.map((box) => ({
    ...box,
    rect: fixRect(box.rect),
  })),
});

const editPageSlice = createSlice({
  name: 'editPage',
  initialState,
  reducers: {
    changeRightSidebarState: (state, { payload }: { payload: boolean }) => {
      localStorage.setItem(
        'appSettings',
        JSON.stringify({
          openRightSidebar: payload,
        })
      );
      state.openRightSidebar = payload;
    },
  },
  extraReducers: ({ addMatcher, addCase }) => {
    // ========================= LOGOUT ========================= //
    addCase(logoutAction, () => {
      localStorage.removeItem('appSettings');
      return initialState;
    });
    addMatcher(
      api.endpoints.tasksControllerGetTask.matchFulfilled,
      (state, { payload }) => {
        state.task = payload;
      }
    );
    addMatcher(
      api.endpoints.tasksControllerGetPagesItems.matchFulfilled,
      (state, { payload, meta }) => {
        for (let i = 0; i < payload.length; i++) {
          state.pages[i + meta.arg.originalArgs.start] =
            payload[i].items.map(fixItem);
        }
      }
    );
    addMatcher(
      api.endpoints.tasksControllerChangeRedactState.matchFulfilled,
      (state, { meta }) => {
        const redactedItemId =
          meta.arg.originalArgs.changeRedactStateDto.itemId;
        const currentRedactStatus =
          meta.arg.originalArgs.changeRedactStateDto.isRedact;
        let newPages: ItemRo[][] = [...state.pages];
        newPages.find((page) => {
          const itemIndex = page.map((item) => item.id).indexOf(redactedItemId);
          if (itemIndex > -1) {
            const item = page[itemIndex];
            if (!currentRedactStatus && item.custom) {
              page.splice(itemIndex, 1);
            } else {
              item.redact = currentRedactStatus;
            }
            return true;
          }
          return false;
        });
        state.pages = newPages;
      }
    );
    addMatcher(
      api.endpoints.tasksControllerDeleteItem.matchFulfilled,
      (state, { meta }) => {
        const deletedItemId = meta.arg.originalArgs.idDto.id;
        let newPages: ItemRo[][] = [...state.pages];
        newPages.find((page) => {
          for (var i = 0; i < page.length; i++) {
            if (page[i].id === deletedItemId) {
              if (page[i].custom) {
                page.splice(i, 1);
                return true;
              } else {
                page[i].redact = false;
              }
            }
          }
          return false;
        });
        state.pages = newPages;
      }
    );
    addMatcher(
      api.endpoints.tasksControllerAddCustomBox.matchFulfilled,
      (state, { payload, meta }) => {
        const pageNumber = meta.arg.originalArgs.addCustomBoxDto.pageNumber;
        let newPages: ItemRo[][] = [...state.pages];
        newPages[pageNumber].push(fixItem(payload));
        state.pages = newPages;
      }
    );
    addMatcher(
      api.endpoints.tasksControllerAddItem.matchFulfilled,
      (state, { payload, meta }) => {
        const pageNumber = meta.arg.originalArgs.addItemDto.pageNumber;
        let newPages: ItemRo[][] = [...state.pages];
        newPages[pageNumber].push(fixItem(payload));
        state.pages = newPages;
      }
    );
    addMatcher(legalActionHandler, (state, { payload, meta }) => {
      const itemId =
        meta.arg.originalArgs.createLegalCodeDto?.itemId ||
        meta.arg.originalArgs.removeLegalCodeDto?.itemId;
      let newPages: ItemRo[][] = [...state.pages];
      for (const page of newPages) {
        const item = page.find((item) => item.id === itemId);
        if (item) {
          item.legalCode =
            meta.arg.originalArgs.createLegalCodeDto?.legalCode || 'NONE';
          return;
        }
      }
    });
    addMatcher(
      api.endpoints.tasksControllerGetTaskOutputState.matchFulfilled,
      (state, { payload }) => {
        if (state.task) {
          state.task.outputState = payload.outputState;
        }
      }
    );
    addMatcher(
      api.endpoints.tasksControllerGenerateOutput.matchFulfilled,
      (state) => {
        if (state.task) {
          state.task.outputState = 'GENERATING_OUTPUT';
        }
      }
    );
  },
});

export const { changeRightSidebarState: changeRightSidebarStateAction } =
  editPageSlice.actions;

export default editPageSlice.reducer;
