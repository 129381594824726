import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { UsageRo } from 'src/app/services/api.generated';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { useGetUsage } from 'src/components/hooks/useGetUsage';

type usageLineType = {
  icon: string;
  title: string;
  count?: UsageRo['workspaceCount'];
  loading?: boolean;
};

const UsageRow: FC<usageLineType> = (props) => {
  const { icon, title, count, loading } = props;
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" columnGap={2}>
        <img
          src={process.env.PUBLIC_URL + '/icons/svg/' + icon}
          width={32}
          height={32}
          alt="task usage icon"
        />
        <Typography fontSize={20}>{title}</Typography>
      </Stack>
      <Typography fontSize={32} fontWeight={600}>
        {loading ? <Skeleton width={60} /> : count}
      </Typography>
    </Stack>
  );
};

type DashboardUsagePropsType = {};

export const DashboardUsage: FC<DashboardUsagePropsType> = () => {
  const { t } = useTranslation();

  const isManager = useAppSelector((state) => state.auth.isManager);

  const { data, isLoading } = useGetUsage(isManager);

  const usageLinesArray = [
    {
      icon: 'archive-grey.svg',
      title: t('workspace'),
      count: data?.workspaceCount || 0,
    },
    {
      icon: 'files.svg',
      title: t('tasks'),
      count: data?.taskCount || 0,
    },
    {
      icon: 'page.svg',
      title: t('pages'),
      count: data?.pageCount || 0,
    },
  ];

  return (
    <Paper
      component={Stack}
      divider={
        <Divider
          orientation="horizontal"
          flexItem
          sx={{ borderColor: 'customGrey.light' }}
        />
      }
      rowGap={1}
      elevation={0}
      sx={{ p: 3, width: '100%' }}
    >
      {usageLinesArray.map((item, index) => (
        <UsageRow key={index} {...item} loading={isLoading} />
      ))}
    </Paper>
  );
};
