import { FC, MouseEvent, useState } from 'react';
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { passwordValidationRegex } from 'src/utils/regexUtils';
import { Form, Formik } from 'formik';
import { formikOnSubmitType } from 'src/types/formTypes';
import {
  useUsersControllerChangePasswordMutation,
  useUsersControllerResetPasswordMutation,
} from 'src/app/services/api.generated';
import { toast } from 'react-toastify';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

const passValidationHandler = (value: string) =>
  !value ? false : passwordValidationRegex.test(value);

type ChangePasswordPropsType = {
  onCloseClick?: () => any;
  resetCode?: string | null;
  email?: string;
};

export const ChangePassword: FC<ChangePasswordPropsType> = ({
  onCloseClick,
  resetCode,
  email,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const navigate = useNavigate(),
    { t } = useTranslation();

  const [changePassword, { isLoading: changeLoading }] =
    useUsersControllerChangePasswordMutation();

  const [resetPassword, { isLoading: resetLoading }] =
    useUsersControllerResetPasswordMutation();

  const showPasswordHandler = () => setShowPassword((prevState) => !prevState);
  const showPasswordConfirmHandler = () =>
    setShowPasswordConfirm((prevState) => !prevState);
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) =>
    event.preventDefault();

  const formValidationSchema = yup.object({
    newPassword: yup
      .string()
      .test(t('passwordValidation'), t('changePassword.hint') || '', (value) =>
        passValidationHandler(value as string)
      )
      .required(t('thisSectionIsRequired') || ''),
    newPasswordConfirm: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), undefined],
        t('changePassword.repeat') || ''
      )
      .required(t('thisSectionIsRequired') || ''),
  });

  const formInitializer = {
    newPassword: '',
    newPasswordConfirm: '',
  };

  const formSubmitHandler: formikOnSubmitType<typeof formInitializer> = (
    { newPassword },
    { setSubmitting, resetForm }
  ) => {
    if (resetCode && email) {
      resetPassword({
        resetCode,
        email,
        changePasswordDto: { password: newPassword },
      })
        .unwrap()
        .then(() => {
          toast.success(t('changePassword.success'));
          navigate('/login');
        });
    } else {
      changePassword({ changePasswordDto: { password: newPassword } })
        .unwrap()
        .then(() => {
          toast.success(t('updatePassword'));
          onCloseClick && onCloseClick();
        });
    }
    setSubmitting(false);
    resetForm();
  };

  return (
    <Formik
      initialValues={formInitializer}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {({ errors, touched, getFieldProps }) => (
        <Form autoComplete="off">
          <Stack rowGap={3} sx={{ p: 3 }}>
            <Typography align="center" fontSize={20} fontWeight={500}>
              {t('changePassword')}
            </Typography>
            <Typography align="center">{t('enterYourPassword')}</Typography>
            <TextField
              label={t('newPassword') + ' *'}
              fullWidth
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={showPasswordHandler}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility sx={{ fill: 'white' }} />
                      ) : (
                        <VisibilityOff sx={{ fill: 'white' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors['newPassword'] && touched['newPassword'])}
              helperText={errors['newPassword']}
              {...getFieldProps('newPassword')}
            />
            <TextField
              label={t('confirmNewPassword') + ' *'}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={showPasswordConfirmHandler}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordConfirm ? (
                        <Visibility sx={{ fill: 'white' }} />
                      ) : (
                        <VisibilityOff sx={{ fill: 'white' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(
                errors['newPasswordConfirm'] && touched['newPasswordConfirm']
              )}
              type={showPasswordConfirm ? 'text' : 'password'}
              helperText={errors['newPasswordConfirm']}
              {...getFieldProps('newPasswordConfirm')}
            />
            <Stack
              sx={{ minWidth: '100%', mt: 3 }}
              direction="row"
              columnGap={1}
            >
              {onCloseClick && (
                <Button
                  size="large"
                  onClick={onCloseClick}
                  fullWidth
                  variant="outlined"
                >
                  {t('cancel')}
                </Button>
              )}
              <LoadingButton
                loading={changeLoading || resetLoading}
                size="large"
                fullWidth
                variant="contained"
                type="submit"
              >
                {t('submit')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
