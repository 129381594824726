import { FC } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'src/configs/theme';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from 'src/createEmotionCache';
import { ToastContainer } from 'react-toastify';
import Router from './routes/Router';

import 'react-toastify/dist/ReactToastify.min.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import 'src/assets/styles/globals.css';

const App: FC = () => {
  return (
    <CacheProvider value={createEmotionCache()}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer limit={3} />
        <Router />
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
