import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SignOutSvg from 'src/components/atoms/svg/SignOutSvg';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/app/reduxCustomHooks';
import { logoutAction } from 'src/app/slices/authSlice';
import { api } from 'src/app/services/api';
import { selectedItemDefiner } from './ListItems';
import { sidebarItemType } from 'src/types/listItemsTypes';
import { SvgIconProps } from '@mui/material/SvgIcon';
import HomeIcon from '@mui/icons-material/Home';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(11)} + 1px)`, // Pas deze waarde aan naar de gewenste breedte
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(11)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(1, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

type SidebarPropsType = {
  openSandwichMenu: boolean;
  setOpenSandwichMenu: React.Dispatch<React.SetStateAction<boolean>>;
  defaultOpen?: boolean;
};

export const Sidebar: React.FC<SidebarPropsType> = ({
  openSandwichMenu,
  setOpenSandwichMenu,
  defaultOpen = false,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [open, setOpen] = React.useState(defaultOpen);

  const handleDrawerOpen = () => {
    setOpen(true);
    setOpenSandwichMenu(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenSandwichMenu(false);
  };

  const isMd = theme.breakpoints.up('md');

  const { isAdmin, isManager } = useAppSelector((state) => ({
    isAdmin: state.auth?.isAdmin,
    isManager: state.auth?.user?.isManager,
  }));

  const logoutHandler = () => {
    !isMd && setOpenSandwichMenu(false);
    dispatch(logoutAction());
    dispatch(api.util.resetApiState());
    navigate('/login');
  };

  const selectedIconRenderHelper: (path: string) => SvgIconProps = (path) => {
    const isSelected = selectedItemDefiner(pathname, path);

    return {
      color: isSelected ? 'primary' : 'action',
      sx: {
        transition: '150ms',
        filter: isSelected
          ? ({ palette }) => `drop-shadow(0 0 0.75rem ${palette.primary.main})`
          : undefined,
      },
    };
  };

  const listItemsArray: sidebarItemType[] = [
    {
      title: t('dashboard'),
      path: '/',
      icon: <HomeIcon  {...selectedIconRenderHelper('/')} />,
    },
    {
      title: t('workspaces'),
      path: '/workspaces',
      icon: <WorkspacesIcon {...selectedIconRenderHelper('/workspaces')} />,
    },
    {
      title: t('profile'),
      path: '/profile',
      icon: <AccountCircleIcon {...selectedIconRenderHelper('/profile')} />,
    },
    {
      title: t('users'),
      path: '/users',
      icon: <PeopleIcon {...selectedIconRenderHelper('/users')} />,
      invisible: !isAdmin && !isManager,
    },
    {
      title: t('companies'),
      path: '/companies',
      icon: <BusinessIcon {...selectedIconRenderHelper('/companies')} />,
      invisible: !isAdmin,
    },
    {
      title: t('dictionary'),
      path: '/dictionary',
      icon: <LibraryBooksIcon {...selectedIconRenderHelper('/dictionary')} />,
    },
  ];

return (
  <Box sx={{ display: 'flex', backgroundColor: 'customGrey.light' }}>
    <CssBaseline />
    <Drawer variant="permanent" open={open}>
      {/* Eerste header voor de afbeelding en hamburger */}
      <DrawerHeader sx={{
        paddingLeft: 2,
        paddingRight: 2,
        marginLeft: -1,
        marginRight: -2,
        backgroundColor: 'customGrey.light',
        display: 'flex',
        flexDirection: 'column',
        alignItems: open ? 'flex-start' : 'center',
        justifyContent: 'center',
        width: '120%',
      }}>
        {/* Afbeelding in de header */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
          <img
            src={process.env.PUBLIC_URL + '/icons/svg/logo.svg'}
            alt="Scann PBL Logo"
            style={{ width: open ? '210px' : '50px', height: '50px', transition: 'width 0.3s ease-out' }}
          />
        </Box>
        {/* Hamburgerpictogram om de zijbalk te openen */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!open && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{
                marginRight: 0,
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          )}
          {/* Als de zijbalk open is, toon knop om de zijbalk in te klappen */}
          {open && (
            <IconButton onClick={handleDrawerClose} sx={{ marginRight: 5 }}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          )}
        </Box>
      </DrawerHeader>
      <Divider sx={{ marginBottom: 0 }} />
      <List sx={{ flexGrow: 1, backgroundColor: 'customGrey.light' }}>
        {listItemsArray.map((item, index) => (
          !item.invisible && (
            <ListItem key={item.title} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 60,
                  justifyContent: 'center',
                  px: 2.5,
                  backgroundColor: 'customGrey.light',
                }}
                onClick={() => navigate(item.path)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: 'center',
                    fontSize: open ? '1.5rem' : '1.2rem',
                  }}
                >
                  {React.cloneElement(item.icon, { style: { fontSize: open ? '2.0rem' : '1.5rem' } })}
                </ListItemIcon>
                <ListItemText primary={item.title} sx={{ display: open ? 'block' : 'none', ml: defaultOpen ? 0 : 3, fontSize: open ? '2rem' : '1.5rem' }} />
              </ListItemButton>
            </ListItem>
          )
        ))}
      </List>
      <Divider sx={{ marginBottom: 5 }} />
      <List>
        {/* Sign-out knop */}
        <ListItem key="signOut" disablePadding sx={{ display: 'block', position: 'absolute', bottom: 0 }}>
          <ListItemButton
            sx={{
              minHeight: 56,
              justifyContent: defaultOpen ? 'initial' : 'center',
              px: 2.5,
              backgroundColor: 'customGrey.light',
            }}
            onClick={logoutHandler}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
                fontSize: '2rem',
              }}
            >
              <SignOutSvg style={{ fontSize: '1.8rem' }} />
            </ListItemIcon>
            <ListItemText primary={t('signOut')} sx={{ display: open ? 'block' : 'none', ml: defaultOpen ? 0 : 3, fontSize: '1.2rem' }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  </Box>
);
};
