import {
  UsageRo,
  useLimitAndUsageControllerGetMyUsageQuery,
  useLimitAndUsageControllerGetUsageQuery,
} from 'src/app/services/api.generated';

type useGetUsageType = (isManager: boolean) => {
  data?: UsageRo | null;
  isLoading: boolean;
};

export const useGetUsage: useGetUsageType = (isManager) => {
  const { data: userUsage, isLoading: userUsageLoading } =
    useLimitAndUsageControllerGetUsageQuery(undefined as void, {
      skip: isManager,
    });

  const { data: companyUsage, isLoading: companyUsageLoading } =
    useLimitAndUsageControllerGetMyUsageQuery(undefined as void, {
      skip: !isManager,
    });

  return {
    data: userUsage || companyUsage || null,
    isLoading: userUsageLoading || companyUsageLoading || false,
  };
};
