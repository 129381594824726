import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type LoadingSvgPropsType = {
  selected?: boolean;
} & SvgIconProps;

const LoadingSvg: FC<LoadingSvgPropsType> = ({
  selected = false,
  ...svgProps
}) => {
  return (
    <SvgIcon
      {...svgProps}
      sx={{
        '& path': {
          fill: selected ? 'white' : ({ palette }) => palette.warning.main,
        },
        ...svgProps.sx,
      }}
    >
      <path d="M 18.480469 21.75 L 5.519531 21.75 C 5.121094 21.75 4.800781 21.414062 4.800781 21 C 4.800781 20.585938 5.121094 20.25 5.519531 20.25 L 18.480469 20.25 C 18.878906 20.25 19.199219 20.585938 19.199219 21 C 19.199219 21.414062 18.878906 21.75 18.480469 21.75 Z M 18.480469 21.75 " />
      <path d="M 17.039062 18 C 16.640625 18 16.320312 17.664062 16.320312 17.25 C 16.316406 15.195312 14.324219 14.011719 12.453125 13.0625 C 12.203125 12.9375 11.941406 12.808594 11.667969 12.675781 C 9.378906 11.566406 6.246094 10.042969 6.238281 6.75 C 6.238281 6.335938 6.5625 6 6.957031 6 L 6.960938 6 C 7.355469 6 7.679688 6.335938 7.679688 6.75 C 7.683594 9.089844 10.125 10.273438 12.277344 11.316406 C 12.558594 11.453125 12.828125 11.585938 13.082031 11.714844 C 14.71875 12.542969 17.757812 14.085938 17.761719 17.25 C 17.761719 17.664062 17.4375 18 17.042969 18 C 17.039062 18 17.039062 18 17.039062 18 Z M 17.039062 18 " />
      <path d="M 6.960938 18 C 6.960938 18 6.960938 18 6.957031 18 C 6.5625 18 6.238281 17.664062 6.238281 17.25 C 6.246094 13.957031 9.378906 12.433594 11.667969 11.324219 C 11.941406 11.191406 12.203125 11.0625 12.453125 10.9375 C 14.324219 9.988281 16.316406 8.804688 16.320312 6.75 C 16.320312 6.335938 16.644531 6 17.039062 6 L 17.042969 6 C 17.4375 6 17.761719 6.335938 17.761719 6.75 C 17.753906 9.914062 14.714844 11.457031 13.082031 12.285156 C 12.828125 12.414062 12.558594 12.546875 12.277344 12.683594 C 10.125 13.726562 7.683594 14.910156 7.679688 17.25 C 7.679688 17.664062 7.355469 18 6.960938 18 Z M 6.960938 18 " />
      <path d="M 17.039062 21.75 C 16.640625 21.75 16.320312 21.414062 16.320312 21 L 16.320312 17.25 C 16.320312 16.835938 16.640625 16.5 17.039062 16.5 C 17.4375 16.5 17.761719 16.835938 17.761719 17.25 L 17.761719 21 C 17.761719 21.414062 17.4375 21.75 17.039062 21.75 Z M 17.039062 21.75 " />
      <path d="M 6.960938 21.75 C 6.5625 21.75 6.238281 21.414062 6.238281 21 L 6.238281 17.25 C 6.238281 16.835938 6.5625 16.5 6.960938 16.5 C 7.359375 16.5 7.679688 16.835938 7.679688 17.25 L 7.679688 21 C 7.679688 21.414062 7.359375 21.75 6.960938 21.75 Z M 6.960938 21.75 " />
      <path d="M 17.039062 7.5 C 16.640625 7.5 16.320312 7.164062 16.320312 6.75 L 16.320312 3 C 16.320312 2.585938 16.640625 2.25 17.039062 2.25 C 17.4375 2.25 17.761719 2.585938 17.761719 3 L 17.761719 6.75 C 17.761719 7.164062 17.4375 7.5 17.039062 7.5 Z M 17.039062 7.5 " />
      <path d="M 6.960938 7.5 C 6.5625 7.5 6.238281 7.164062 6.238281 6.75 L 6.238281 3 C 6.238281 2.585938 6.5625 2.25 6.960938 2.25 C 7.359375 2.25 7.679688 2.585938 7.679688 3 L 7.679688 6.75 C 7.679688 7.164062 7.359375 7.5 6.960938 7.5 Z M 6.960938 7.5 " />
      <path d="M 18.480469 3.75 L 5.519531 3.75 C 5.121094 3.75 4.800781 3.414062 4.800781 3 C 4.800781 2.585938 5.121094 2.25 5.519531 2.25 L 18.480469 2.25 C 18.878906 2.25 19.199219 2.585938 19.199219 3 C 19.199219 3.414062 18.878906 3.75 18.480469 3.75 Z M 18.480469 3.75 " />
    </SvgIcon>
  );
};

export default LoadingSvg;
