import { FC } from 'react';
import { shallowEqual } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/app/reduxCustomHooks';
import { logoutAction } from 'src/app/slices/authSlice';
import { isExpiredJwt } from 'src/utils/parseJwtUtils';

export const PrivateRoute: FC = () => {
  const token = useAppSelector((state) => state.auth?.token, shallowEqual);
  const dispatch = useAppDispatch();

  const hasValidToken = token ? !isExpiredJwt(token) : false;

  if (token && isExpiredJwt(token)) {
    dispatch(logoutAction());
  }

  return hasValidToken ? <Outlet /> : <Navigate to="/login" />;
};
