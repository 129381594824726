import { SvgIcon, SvgIconProps } from '@mui/material';

const FilesSvg = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      sx={{
        fill: 'none',
        '&>path': {
          fill: '#9FA2B4',
        },
        ...props.sx,
      }}
    >
      <path d="M20.8734 2.7168H20.1717V19.9574C20.1717 21.1028 19.2394 22.0351 18.094 22.0351H10.9837H5.32959V22.6742C5.32959 23.406 5.92357 24 6.6554 24H13.7632H20.8709C21.6028 24 22.1967 23.406 22.1967 22.6742V4.04261C22.1967 3.31078 21.6053 2.7168 20.8734 2.7168Z" />
      <path d="M10.9838 21.2832H18.0916C18.8234 21.2832 19.4174 20.6892 19.4174 19.9574V2.71679V1.32581C19.4174 0.593985 18.8234 0 18.0916 0H10.9838H8.6204V0.438596C8.62539 0.483709 8.62792 0.531328 8.62792 0.578947V3.46867V4.21053C8.62792 5.2406 7.79082 6.07769 6.76075 6.07769H6.0189H3.12917C3.09158 6.07769 3.05148 6.07519 3.01389 6.07268H2.55273V19.9574C2.55273 20.6892 3.14672 21.2832 3.87855 21.2832H5.33218H10.9838ZM11.252 16.7769H6.61789C6.27203 16.7769 5.99133 16.4962 5.99133 16.1504C5.99133 15.8045 6.27203 15.5238 6.61789 15.5238H11.2545C11.6003 15.5238 11.881 15.8045 11.881 16.1504C11.881 16.4962 11.5978 16.7769 11.252 16.7769ZM15.8886 13.579H6.61789C6.27203 13.579 5.99133 13.2982 5.99133 12.9524C5.99133 12.6065 6.27203 12.3258 6.61789 12.3258H15.8886C16.2344 12.3258 16.5151 12.6065 16.5151 12.9524C16.5151 13.2982 16.2344 13.579 15.8886 13.579ZM6.61789 8.92982H15.8886C16.2344 8.92982 16.5151 9.21053 16.5151 9.55639C16.5151 9.90226 16.2344 10.183 15.8886 10.183H6.61789C6.27203 10.183 5.99133 9.90226 5.99133 9.55639C5.99133 9.21053 6.27203 8.92982 6.61789 8.92982Z" />
      <path d="M3.1291 5.32578H6.76068C6.76318 5.32578 6.7682 5.32578 6.7707 5.32578C7.37722 5.32077 7.86845 4.82954 7.87346 4.22303C7.87346 4.22052 7.87346 4.21551 7.87346 4.213V0.578917C7.87346 0.243077 7.59777 0.0200195 7.31208 0.0200195C7.17424 0.0200195 7.03639 0.0701448 6.9211 0.185433L2.73311 4.3734C2.38223 4.72428 2.63035 5.32578 3.1291 5.32578Z" />
    </SvgIcon>
  );
};

export default FilesSvg;
