import { FC } from 'react';
import { Box, Container, Stack, useMediaQuery } from '@mui/material';
// import ErrorBoundary from '../HOC/ErrorBoundaries';
import { useTheme } from '@mui/system';
import { LanguageSwitcher } from '../organisms/layout/navbar/LanguageSwitcher';

type LoginTemplatePropsType = {
  children: any;
};

const LoginTemplate: FC<LoginTemplatePropsType> = ({ children }) => {
  const theme = useTheme(),
    isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          height: '100vh',
          backgroundColor: 'customGrey.background',
          position: 'relative',
          minHeight: '600px',
          minWidth: '300px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            right: { xs: 10, sm: 15, md: 20 },
            top: { xs: 10, sm: 15, md: 20 },
          }}
        >
          <LanguageSwitcher />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            width: '86px',
            height: '44px',
            left: { xs: 10, sm: 15, md: 20 },
            top: { xs: 10, sm: 15, md: 20 },
          }}
        >
          <img
            width={!isMd ? 76 : 86}
            height={!isMd ? 34 : 44}
            src={process.env.PUBLIC_URL + '/icons/svg/logo.svg'}
            alt="PBL scann company logo"
          />
        </Box>
        <Container
          maxWidth="lg"
          sx={{
            height: { xs: '100%', md: 'auto' },
            p: { xs: 0, md: 'auto' },
            m: { xs: 0, md: 'auto' },
            borderRadius: { xs: 0, md: 'auto' },
          }}
        >
          {children}
        </Container>
      </Stack>
    </>
  );
};

export default LoginTemplate;
