import { FC } from 'react';
import { Navigate } from 'react-router';
import { Box, Paper, Stack } from '@mui/material';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { SignIn } from 'src/components/organisms/auth/SignIn';
import LoginTemplate from 'src/components/templates/LoginTemplate';
import { LoginSvg } from 'src/components/atoms/svg/LoginSvg';

const Login: FC = () => {
  const token = useAppSelector((state) => state.auth.token);

  if (token) return <Navigate to="/" />;

  return (
    <LoginTemplate>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{
          width: { xs: '100%', md: 'auto' },
          height: { xs: '100%', md: 'auto' },
        }}
      >
        <Box sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
          <LoginSvg
            sx={{
              width: { md: 450, lg: 550 },
              height: { md: 450, lg: 550 },
            }}
          />
        </Box>
        <Paper
          elevation={0}
          sx={{
            minWidth: { xs: '100%', md: '400px', lg: '450px' },
            minHeight: { xs: '100%', md: 'auto' },
            borderRadius: {
              xs: 0,
              md: 0.8,
            },
            textAlign: 'center',
            px: { xs: 5, sm: 10, md: 3, lg: 6 },
            pt: { xs: 8, md: 3, lg: 6 },
            pb: { xs: 2, md: 3, lg: 6 },
          }}
        >
          <Box
            sx={{
              display: { xs: 'inline-flex', md: 'none' },
              mb: { xs: 1, sm: 2, md: 3, lg: 0 },
            }}
          >
            <LoginSvg
              sx={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
          <SignIn />
        </Paper>
      </Stack>
    </LoginTemplate>
  );
};

export default Login;
