import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { api } from './services/api';
import auth from './slices/authSlice';
import editPage from './slices/editPageSlice';
import user from './slices/userSlice';
import company from './slices/companySlice';
import notification from './slices/notificationSlice';

export const store = configureStore({
  reducer: {
    auth,
    editPage,
    user,
    company,
    notification,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootStateType = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootStateType,
  unknown,
  Action<string>
>;
