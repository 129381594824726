import { FC } from 'react';
import { useNavigate } from 'react-router';
import {
  Button,
  ButtonGroup,
  Container,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type EmptyPagePropsType = {
  titleElement?: any;
  titleTxt?: string;
};

const EmptyPage: FC<EmptyPagePropsType> = ({ titleElement, titleTxt }) => {
  const navigate = useNavigate(),
    { t } = useTranslation();

  const goHomeHandler = () => navigate('/');
  const goBackHandler = () => navigate(-1);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%', height: '100vh' }}
    >
      <Container maxWidth="xs">
        <Paper sx={{ p: 3 }}>
          {titleElement
            ? titleElement
            : titleTxt && (
                <Typography
                  variant="h5"
                  align="center"
                  paragraph
                  sx={{ mb: 4 }}
                >
                  {t(titleTxt)}
                </Typography>
              )}
          <ButtonGroup fullWidth variant="contained">
            <Button fullWidth size="large" onClick={goBackHandler}>
              {t('goBack')}
            </Button>
            <Button fullWidth size="large" onClick={goHomeHandler}>
              {t('goHome')}
            </Button>
          </ButtonGroup>
        </Paper>
      </Container>
    </Stack>
  );
};

export default EmptyPage;
