import jwt_decode from 'jwt-decode';

type jwtType = { exp: number; iat: number; username: string };

export const isExpiredJwt = (token: string) => {
  const jwt: jwtType | undefined | null = jwt_decode(token);
  if (!jwt) return true;
  const now = new Date().getTime() / 1000;
  return now >= jwt.exp;
};
