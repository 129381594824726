import type { FC, MouseEvent } from 'react';
import { useState, useMemo } from 'react';
import { Table, TableContainer } from '@mui/material';
import { TaskRo } from 'src/app/services/api.generated';
import { CustomTablePagination } from '../../../molecules/CustomTablePagination';
import { TasksTableHeader } from './TasksTableHeader';
import { TasksTableBody } from './TasksTableBody';
import { TaskTableToolbar } from './TaskTableToolbar';
import { tableOrderType } from 'src/types/generalTypes';
import { useTranslation } from 'react-i18next';
import { EmptyList } from 'src/components/molecules/EmptyList';

type TasksTablePropsType = {
  data: TaskRo[];
  isLoading: boolean;
  pagination?: boolean;
  showToolbar?: boolean;
  showCheckbox?: boolean;
  goToCreateTaskPage?: () => void;
};

export const TasksTable: FC<TasksTablePropsType> = ({
  data,
  isLoading,
  pagination = true,
  showToolbar = true,
  showCheckbox = true,
  goToCreateTaskPage,
}) => {
  const [order, setOrder] = useState<tableOrderType>('desc');
  const [orderBy, setOrderBy] = useState<keyof TaskRo>('createdAt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selected, setSelected] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState('');

  const { t } = useTranslation();

  const filteredData = useMemo(
    () =>
      searchInput
        ? data.filter(({ name }) => name.includes(searchInput))
        : data,
    [data, searchInput]
  );

  const handleRequestSort = (
    _: MouseEvent<unknown>,
    property: keyof TaskRo
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  if (!isLoading && !data.length) {
    return (
      <>
        {showToolbar && (
          <TaskTableToolbar
            rows={data}
            selected={selected}
            setSelected={setSelected}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            goToCreateTaskPage={goToCreateTaskPage}
          />
        )}
        <EmptyList text={t('tasksListIsEmpty')} />
      </>
    );
  }

  return (
    <>
      {showToolbar && (
        <TaskTableToolbar
          rows={filteredData}
          selected={selected}
          setSelected={setSelected}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          goToCreateTaskPage={goToCreateTaskPage}
        />
      )}
      <TableContainer>
        <Table size="small">
          <TasksTableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rows={filteredData}
            selected={selected}
            setSelected={setSelected}
            showCheckbox={showCheckbox}
          />
          <TasksTableBody
            isLoading={isLoading}
            rows={filteredData}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            selected={selected}
            setSelected={setSelected}
            showCheckbox={showCheckbox}
          />
        </Table>
      </TableContainer>
      {pagination && (
        <CustomTablePagination
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleChangePage={handleChangePage}
          rowsLength={filteredData.length}
          colSpan={7}
        />
      )}
    </>
  );
};
