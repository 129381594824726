import { TaskRo, WorkspaceRo } from 'src/app/services/api.generated';
import { WordRo } from 'src/app/services/api.generated';
import { tableOrderType, taskStateType } from 'src/types/generalTypes';
import { companyFlatType } from './companiesArrayFlattenerUtils';
import { userFlatType } from './usersArrayFlattenerUtils';

// ================================== task definer ============================================

export const isTask = (taskOrWorkspace: TaskRo | WorkspaceRo) =>
  (taskOrWorkspace as TaskRo).numberOfPages !== undefined;

// ================================== comparator ============================================

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function taskGetComparator<Key extends keyof TaskRo>(
  order: tableOrderType,
  orderBy: Key
): (a: TaskRo, b: TaskRo) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function userGetComparator<Key extends keyof userFlatType>(
  order: tableOrderType,
  orderBy: Key
): (a: userFlatType, b: userFlatType) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function companiesGetComparator<Key extends keyof companyFlatType>(
  order: tableOrderType,
  orderBy: Key
): (a: companyFlatType, b: companyFlatType) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function dictionaryGetComparator<Key extends keyof WordRo>(
  order: tableOrderType,
  orderBy: Key
): (a: WordRo, b: WordRo) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// ================================== divide task from workspace ============================================

type taskWorkspaceDividerType = {
  tasksAndWorkspace: WorkspaceRo[] | TaskRo[];
  state: taskStateType;
};

export const taskWorkspaceDivider = ({
  tasksAndWorkspace,
}: taskWorkspaceDividerType) => {
  let tasks: TaskRo[] = [];
  let workspaces: WorkspaceRo[] = [];
  // eslint-disable-next-line array-callback-return
  tasksAndWorkspace.map((item) => {
    if (isTask(item)) {
      tasks.push(item as TaskRo);
    } else {
      workspaces.push(item as WorkspaceRo);
    }
  });
  return {
    tasks,
    workspaces,
  };
};
