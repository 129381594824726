import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Container, Stack, useMediaQuery, AppBar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Navbar } from '../organisms/layout/navbar/Navbar';
import { Sidebar } from '../organisms/layout/Sidebar/Sidebar';
import { useLocation } from 'react-router';
import { EditPageHeader } from '../organisms/editPage/header/EditPageHeader';

export const sidebarMinWidth = 82;
export const workspaceSidebarWidth = 250;

// * sidebar converting from temporary to permanent when window size be grater than "md" breakpoint

type DashboardTemplatePropsType = {
  navbarComponent?: any;
  children?: any;
};

export const DashboardTemplate: FC<DashboardTemplatePropsType> = ({
  navbarComponent,
  children,
}) => {
  const theme = useTheme(),
    { pathname } = useLocation();

  const isEditPage = useMemo(() => pathname.includes('edit'), [pathname]);

  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const [openSandwichMenu, setOpenSandwichMenu] = useState(isMd);

  useEffect(() => {
    !isMd && setOpenSandwichMenu(false);
  }, [isMd]);

  const contentBoxMinHeight = useMemo(() => {
    let result = '0';
    if (isEditPage) {
      result = `calc(100% - ${isMd ? 67.5 : 56}px)`;
    } else {
      result = `calc(100% - ${isMd ? 87.5 : 67}px)`;
    }
    return result;
  }, [isEditPage, isMd]);

  return (
    <Stack
      direction="row"
      sx={{
        height: '99.99vh',
        width: '100vw',
        maxWidth: '100vw',
        overflowX: 'hidden',
        backgroundColor: 'customGrey.background',
      }}
    >
      <Sidebar
        openSandwichMenu={openSandwichMenu}
        setOpenSandwichMenu={setOpenSandwichMenu}
      />
      <Stack
        sx={{
          width: { xs: '100%', md: `calc(100% - ${sidebarMinWidth}px)` },
          height: '100%',
        }}
      >
        <AppBar
          elevation={0}
          sx={{
            width: '100%',
            pl: isMd ? `${sidebarMinWidth}px` : 0,
            backgroundColor: theme.palette.customGrey.background,
            flex: '0 1 auto',
            transition: theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            ...(isMd &&
              openSandwichMenu && {
                width: '100%',
                transition: theme.transitions.create(['width', 'margin'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              }),
          }}
        >
          {navbarComponent || isEditPage ? (
            <EditPageHeader />
          ) : (
            <Navbar setOpenSandwichMenu={setOpenSandwichMenu} />
          )}
        </AppBar>
        <Box
          component="main"
          sx={{
            position: 'relative',
            width: '100%',
          }}
        >
          {isEditPage ? (
            <EditPageHeader />
          ) : (
            <Box sx={{ visibility: 'hidden' }}>
              <Navbar setOpenSandwichMenu={setOpenSandwichMenu} />
            </Box>
          )}
          <Container
            sx={{
              minHeight: contentBoxMinHeight,
              p: isEditPage ? '0 !important' : { xs: 2, md: 4 },
              width: '100%',
              overflowX: 'hidden',
            }}
            maxWidth={isEditPage ? false : 'lg'}
          >
            {children}
          </Container>
        </Box>
      </Stack>
    </Stack>
  );
};
