import type { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const NotifSvg: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...props}
      sx={{
        fill: 'none',
        '&>path': {
          fill: 'white',
        },
        ...props.sx,
      }}
    >
      <path d="M11.5757 29.1133C12.3269 30.8116 14.0268 32.0001 16.0001 32.0001C17.9734 32.0001 19.6733 30.8116 20.4245 29.1133H11.5757Z" />
      <path d="M15.9997 3.05729C17.3019 3.05729 18.5522 3.28704 19.7124 3.70767V3.56475C19.7124 1.59914 18.1133 0 16.1478 0H15.8523C13.8867 0 12.2876 1.59914 12.2876 3.56475V3.70586C13.4473 3.28648 14.6972 3.05729 15.9997 3.05729Z" />
      <path d="M27.7174 27.2353H4.28266C3.8393 27.2353 3.43319 26.9363 3.34098 26.5026C3.2497 26.0732 3.4454 25.6494 3.84243 25.4659C3.93665 25.4048 4.64582 24.909 5.3558 23.4087C6.65976 20.6535 6.93346 16.7724 6.93346 14.0017C6.93346 9.00256 11.0006 4.93555 15.9997 4.93555C20.987 4.93555 25.0466 8.98297 25.0659 13.9657C25.0664 13.9777 25.0666 13.9897 25.0666 14.0017C25.0666 16.7724 25.3403 20.6535 26.6443 23.4087C27.3543 24.909 28.0634 25.4049 28.1576 25.4659C28.5547 25.6494 28.7504 26.0731 28.6591 26.5026C28.5669 26.9362 28.1608 27.2353 27.7174 27.2353ZM28.1682 25.4726H28.1688H28.1682Z" />
    </SvgIcon>
  );
};
