import { FC, useMemo } from 'react';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import {
  useWorkspacesControllerGetFolderByIdQuery,
  useWorkspacesControllerGetWorkspaceByIdQuery,
} from 'src/app/services/api.generated';

type previousPathType = {
  title: string;
  href: string;
  index?: number;
};

const previousPath = ({ title, href, index }: previousPathType) => (
  <Link to={href} key={index} style={{ textDecoration: 'none' }}>
    <Typography
      sx={{ cursor: 'pointer' }}
      color="primary"
      fontSize={13}
      fontWeight={400}
    >
      {title}
    </Typography>
  </Link>
);

const lastPath = ({ title, href, index }: previousPathType) => (
  <Typography key={index} fontSize={13} fontWeight={400}>
    {title}
  </Typography>
);

export const BreadCrumbs: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const pathItems = pathname.replace(/^\/|\/$/g, '').split('/');
  const isInWorkspaces = pathItems[0] === 'workspaces';
  const isInNotifications = pathItems[0] === 'notifications';

  const { firstName } = useAppSelector((state) => ({
    firstName: state.auth.user?.firstName,
  }));

  const { data: workspace } = useWorkspacesControllerGetWorkspaceByIdQuery(
    { id: pathItems[1] },
    { skip: !isInWorkspaces || pathItems.length < 2 }
  );

  const { data: folder } = useWorkspacesControllerGetFolderByIdQuery(
    {
      workspaceId: workspace?.id as string,
      folderId: pathItems[2] as string,
    },
    { skip: !workspace?.id || pathItems.length < 3 }
  );

  const showItems = useMemo(() => {
    if (pathname === '/') {
      return [{ title: `${t('welcome')} ${firstName}`, href: '/' }];
    }
    if (isInNotifications) {
      return [{ title: t('home'), href: '/' }];
    }

    return pathItems.reduce(
      (prev_res, path, index) => {
        const href = prev_res[prev_res.length - 1].href + path + '/';
        const title =
          (isInWorkspaces &&
            (index === 0
              ? 'workspaces'
              : index === 1
              ? workspace?.name
              : folder?.name)) ||
          (t(path) as string);
        return [...prev_res, { title, href }];
      },
      [{ title: t('home'), href: '/' }]
    );
  }, [
    pathname,
    isInNotifications,
    pathItems,
    t,
    firstName,
    isInWorkspaces,
    workspace?.name,
    folder?.name,
  ]);

  return (
    <Breadcrumbs
      separator={
        <Box
          sx={{
            color: ({ palette }) => palette.primary.light,
            fontSize: '13px',
            fontWeight: '400',
          }}
        >
          \
        </Box>
      }
      aria-label="breadcrumb"
      sx={{ width: '100%', display: { xs: 'none', md: 'block' } }}
    >
      {showItems.map(({ title, href }, index) => {
        if (showItems.length === 1) {
          return lastPath({ title, href, index });
        } else {
          if (index === showItems.length - 1) {
            return lastPath({ title, href, index });
          } else {
            return previousPath({ title, href, index });
          }
        }
      })}
    </Breadcrumbs>
  );
};
