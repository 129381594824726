import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type ResumeSvgPropsType = {
  isDisable?: boolean;
} & SvgIconProps;

const ResumeImageSvg: FC<ResumeSvgPropsType> = ({
  isDisable = false,
  ...svgProps
}) => {
  return (
    <SvgIcon
      viewBox="0 0 72 58"
      width={72}
      height={72}
      sx={{
        '& path': {
          fill: isDisable
            ? ({ palette }) => palette.customGrey.ultraLight
            : undefined,
        },
      }}
      {...svgProps}
    >
      <path
        d="M19.9513 6.88982V0.381836H35.3446C36.1269 0.381836 36.8298 0.859399 37.1181 1.58671L39.2202 6.88996L30.0921 9.29999L19.9513 6.88982Z"
        fill="#8AC9FE"
      />
      <path
        d="M39.2199 6.88912H36.224L34.1218 1.58601C33.8331 0.85982 33.13 0.381836 32.3474 0.381836H35.3447C36.1273 0.381836 36.8304 0.85968 37.1177 1.58601L39.2199 6.88912Z"
        fill="#60B7FF"
      />
      <path
        d="M53.0252 6.88972H25.1958C24.4135 6.88972 23.7105 6.41215 23.4222 5.68484L21.7977 1.58647C21.5094 0.859295 20.8065 0.381592 20.0242 0.381592H6.53878C5.48508 0.381592 4.63092 1.23575 4.63092 2.28945V4.98186C4.63092 6.03556 3.77677 6.88972 2.72306 6.88972H2.547C1.14033 6.88972 0 8.03018 0 9.43686V48.5077C0 50.8634 1.90969 52.7733 4.26558 52.7733H55.5723V9.43686C55.5723 8.03018 54.4319 6.88972 53.0252 6.88972Z"
        fill="#FEF0AE"
      />
      <path
        d="M55.5723 12.7394V52.7732H4.2653C3.71376 52.7732 3.18825 52.6693 2.7045 52.4772C3.68339 52.0816 4.42125 51.2875 4.93523 50.1296C5.15897 49.6244 5.30916 49.0901 5.40591 48.5458C5.40591 48.5458 11.3341 15.2493 11.3643 14.989C11.5113 13.7215 12.5869 12.7393 13.894 12.7393H55.5723V12.7394Z"
        fill="#FEE97D"
      />
      <path
        d="M25.1962 6.88912H22.3086C21.526 6.88912 20.8229 6.41268 20.5342 5.68495L18.91 1.58601C18.6227 0.85982 17.9196 0.381836 17.137 0.381836H20.0246C20.8072 0.381836 21.5103 0.85968 21.7976 1.58601L23.4218 5.68495C23.7105 6.41254 24.4136 6.88912 25.1962 6.88912Z"
        fill="#FEE97D"
      />
      <path
        d="M55.5723 9.43743V52.7733H52.6847V9.43743C52.6847 8.02977 51.5441 6.88916 50.1379 6.88916H53.0255C54.4317 6.88916 55.5723 8.02977 55.5723 9.43743Z"
        fill="#FEE97D"
      />
      <path
        d="M17.0768 12.7388H64.5366C65.9433 12.7388 67.0838 13.8791 67.0838 15.2859L61.086 50.2262C61.086 51.6328 59.9456 52.7733 58.5388 52.7733H8.53187H4.26587C6.11889 52.7733 7.39351 51.8268 8.15598 50.0413C8.36031 49.5627 8.49784 49.0581 8.58939 48.5458L14.4436 15.7678C14.4897 15.5099 14.5166 15.2492 14.5468 14.9889C14.6939 13.722 15.7705 12.7388 17.0768 12.7388Z"
        fill="#FEE45A"
      />
      <path
        d="M67.0839 15.2864L61.0864 50.2265C61.0864 51.6327 59.9458 52.7733 58.5395 52.7733H55.5855C56.9918 52.7733 58.1324 51.6327 58.1324 50.2265L64.1299 15.2864C64.1299 13.8787 62.9893 12.7395 61.5831 12.7395H64.537C65.9433 12.7395 67.0839 13.8786 67.0839 15.2864Z"
        fill="#FED402"
      />
      <path
        d="M26.5475 42.8862H17.6155C17.0331 42.8862 16.5609 42.4142 16.5609 41.8315C16.5609 41.2489 17.0331 40.7769 17.6155 40.7769H26.5475C27.1299 40.7769 27.6022 41.2489 27.6022 41.8315C27.6022 42.4142 27.1299 42.8862 26.5475 42.8862Z"
        fill="#FAC600"
      />
      <path
        d="M30.0921 47.1804H15.8829C15.3004 47.1804 14.8282 46.7083 14.8282 46.1257C14.8282 45.5431 15.3004 45.071 15.8829 45.071H30.0921C30.6745 45.071 31.1468 45.5431 31.1468 46.1257C31.1468 46.7083 30.6745 47.1804 30.0921 47.1804Z"
        fill="#FAC600"
      />
      <path
        d="M45.0194 48.6734L38.5598 45.1909L35.6136 56.7268C35.4795 57.2518 35.9564 57.7288 36.4814 57.5946L48.0172 54.6484L45.0194 48.6734Z"
        fill="#FEF1DA"
      />
      <path
        d="M48.017 54.6489L36.4809 57.5943C35.9568 57.7286 35.479 57.2521 35.6131 56.7266L35.9712 55.3261L46.1067 52.7388L48.017 54.6489Z"
        fill="#FAE2C0"
      />
      <path
        d="M41.6675 56.2706L36.4817 57.5949C35.9567 57.7289 35.4798 57.252 35.6139 56.7271L36.9381 51.5413L41.6675 56.2706Z"
        fill="#7CA1B1"
      />
      <path
        d="M41.6671 56.2701L36.481 57.5955C35.9568 57.7284 35.4804 57.2518 35.6132 56.7277V56.7263L35.9712 55.3258L39.7568 54.3599L41.6671 56.2701Z"
        fill="#678D98"
      />
      <path
        d="M67.4713 35.1945L63.2886 29.3296L58.0139 25.7371L54.6244 29.1265L58.3332 35.1949L64.0818 38.584L67.4713 35.1945Z"
        fill="#7CA1B1"
      />
      <path
        d="M65.558 33.2722L67.4688 35.183L64.0798 38.572L62.169 36.6612L65.558 33.2722Z"
        fill="#678D98"
      />
      <path
        d="M70.8345 26.2046L67.0037 22.3738C65.45 20.8201 62.9307 20.8201 61.3769 22.3738L58.0139 25.7369L67.4713 35.1945L70.8344 31.8314C72.3883 30.2777 72.3883 27.7584 70.8345 26.2046Z"
        fill="#EAF6FF"
      />
      <path
        d="M70.8348 31.8308L67.4707 35.1948L65.5577 33.2817L68.9159 29.9249C70.4694 28.3714 70.4694 25.852 68.9159 24.2983L70.8348 26.2042C72.3884 27.7579 72.3884 30.2773 70.8348 31.8308Z"
        fill="#D8ECFE"
      />
      <path
        d="M54.6237 29.1177L64.0798 38.5738L48.0177 54.6359L38.5616 45.1798L54.6237 29.1177Z"
        fill="#EE6161"
      />
      <path
        d="M62.1698 36.6633L64.0802 38.5737L48.0181 54.6358L46.1077 52.7254L62.1698 36.6633Z"
        fill="#E94444"
      />
    </SvgIcon>
  );
};

export default ResumeImageSvg;
