import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SyncSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      sx={{
        fill: 'none',
        ...props.sx,
      }}
    >
      <path
        d="M 3 12 C 3 12.414062 2.664062 12.75 2.25 12.75 C 1.835938 12.75 1.5 12.414062 1.5 12 C 1.5 6.210938 6.210938 1.5 12 1.5 C 14.886719 1.5 17.554688 2.648438 19.5 4.628906 L 19.5 2.25 C 19.5 1.835938 19.835938 1.5 20.25 1.5 C 20.664062 1.5 21 1.835938 21 2.25 L 21 6.75 C 21 7.164062 20.664062 7.5 20.25 7.5 L 15.75 7.5 C 15.335938 7.5 15 7.164062 15 6.75 C 15 6.335938 15.335938 6 15.75 6 L 18.734375 6 C 17.050781 4.105469 14.628906 3 12 3 C 7.039062 3 3 7.039062 3 12 Z M 21.75 11.25 C 21.335938 11.25 21 11.585938 21 12 C 21 16.960938 16.960938 21 12 21 C 9.371094 21 6.949219 19.894531 5.265625 18 L 8.25 18 C 8.664062 18 9 17.664062 9 17.25 C 9 16.835938 8.664062 16.5 8.25 16.5 L 3.75 16.5 C 3.335938 16.5 3 16.835938 3 17.25 L 3 21.75 C 3 22.164062 3.335938 22.5 3.75 22.5 C 4.164062 22.5 4.5 22.164062 4.5 21.75 L 4.5 19.371094 C 6.445312 21.351562 9.113281 22.5 12 22.5 C 17.789062 22.5 22.5 17.789062 22.5 12 C 22.5 11.585938 22.164062 11.25 21.75 11.25 Z M 21.75 11.25"
        fill="#00C2FF"
      />
    </SvgIcon>
  );
};
