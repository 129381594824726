import type { FC, MouseEventHandler } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

type ConfirmDialogPropsType = {
  title?: any;
  warningText?: any;
  dialogProps?: DialogProps;
  onSubmitClick: () => any;
  submitText?: any;
  isSubmitLoading?: boolean;
};

export const ConfirmDialog: FC<ConfirmDialogPropsType> = ({
  title,
  warningText,
  dialogProps = { open: true, onClose: () => {} } as DialogProps,
  onSubmitClick,
  submitText,
  isSubmitLoading = false,
}) => {
  const { t } = useTranslation();

  const cancelButtonOnClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (!dialogProps || !dialogProps.onClose) return;
    dialogProps.onClose(event, 'escapeKeyDown');
  };

  const submitButtonOnClick = () => onSubmitClick();

  return (
    <Dialog {...dialogProps}>
      <DialogTitle align="center" color="yellow">
        {title ? title : `${t('attention')}!`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="center">
          {warningText ? warningText : t('doYouApproveOfThisChange')}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: { xs: 'column', sm: 'row', gap: 15 },
        }}
      >
        <Button
          sx={{ width: '90%' }}
          fullWidth
          variant="outlined"
          onClick={(e) => cancelButtonOnClick(e)}
        >
          {t('cancel')}
        </Button>
        <LoadingButton
          sx={{ m: '0 !important', width: '90%' }}
          fullWidth
          variant="contained"
          loading={isSubmitLoading}
          onClick={submitButtonOnClick}
        >
          {submitText ? submitText : t('confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
