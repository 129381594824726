import { FC, useEffect, useMemo } from 'react';
import { Typography, Stack, Skeleton, IconButton, Zoom } from '@mui/material';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { useTranslation } from 'react-i18next';
import {
  useTasksControllerGenerateOutputMutation,
  useTasksControllerGetTaskOutFileUrlQuery,
} from 'src/app/services/api.generated';
import { LoadingButton } from '@mui/lab';
import { Download as DownloadIcon } from '@mui/icons-material';
import { shallowEqual } from 'react-redux';
import { useLazyTasksControllerGetTaskOutputStateQuery } from 'src/app/services/api';

export const EditPageHeader: FC = () => {
  const { t } = useTranslation();
  const { id, outputState, name, numberOfPages } = useAppSelector((state) => {
    const { id, outputState, name, numberOfPages } = state.editPage.task || {};
    return {
      id,
      outputState,
      name,
      numberOfPages,
    };
  }, shallowEqual);

  const [generateOutput, { isLoading }] =
    useTasksControllerGenerateOutputMutation();

  const isGenerating = useMemo(
    () =>
      outputState === 'GENERATING_OUTPUT' || outputState === 'GENERATE_OUTPUT',
    [outputState]
  );

  const [getTaskOutputState] = useLazyTasksControllerGetTaskOutputStateQuery();

  const { data: outFileUrl } = useTasksControllerGetTaskOutFileUrlQuery(
    {
      taskId: id!,
    },
    { skip: !id || outputState !== 'GENERATED_OUTPUT' }
  );

  useEffect(() => {
    if (!isGenerating || !id) return;
    const getStateInterval = setInterval(() => {
      getTaskOutputState({
        id,
      })
        .unwrap()
        .then((res) => {
          if (res.outputState === 'GENERATED_OUTPUT') {
            clearInterval(getStateInterval);
          }
        });
    }, 4000);

    return () => {
      clearInterval(getStateInterval);
    };
  }, [isGenerating, id, getTaskOutputState]);

  const generateDocClickHandler = () => {
    if (!id) return;
    generateOutput({ idDto: { id } }).unwrap();
  };

  const downloadFileClickHandler = () => {
    if (!outFileUrl) return;
    let anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.href = outFileUrl.url;
    anchor.download =
      name?.split('.').slice(0, -1).join('.') + `(${t('redacted')})` ||
      'outputFile';
    anchor.click();
  };

  const canDownload = outputState === 'GENERATED_OUTPUT';

  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: 'customGrey.light',
        p: 2,
        position: 'relative',
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        align="center"
        fontWeight="bold"
        color="white"
        noWrap
        textOverflow="ellipsis"
        overflow="hidden"
        sx={{ maxWidth: '300px' }}
      >
        {!name ? <Skeleton width={80} /> : name}
      </Typography>
      <Typography sx={{ ml: 1 }} align="center" color="white">
        {!numberOfPages ? (
          <Skeleton width={70} />
        ) : (
          `(${numberOfPages || 0} ${t('pages')})`
        )}
      </Typography>
      <Stack
        sx={{
          position: 'absolute',
          right: 15,
        }}
        direction="row"
        spacing={0.1}
      >
        <LoadingButton
          disabled={!id}
          loading={isGenerating || isLoading}
          onClick={generateDocClickHandler}
          size="small"
          variant="outlined"
          sx={{
            color: 'white',
            mr: 2,
          }}
        >
          {t('generateDoc')}
        </LoadingButton>
        {canDownload && (
          <Zoom in={canDownload} timeout={canDownload ? 500 : 100}>
            <IconButton
              onClick={downloadFileClickHandler}
              color="primary"
              size="small"
            >
              <DownloadIcon />
            </IconButton>
          </Zoom>
        )}
      </Stack>
    </Stack>
  );
};
