import type { Dispatch, FC, SetStateAction } from 'react';
import {
  Box,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation } from 'react-router';
import { sidebarItemType } from 'src/types/listItemsTypes';
import { useAppDispatch } from 'src/app/reduxCustomHooks';
import { setSelectedUserAction } from 'src/app/slices/userSlice';
import ListSubItems from './ListSubItems';
import CustomListItemButton from 'src/components/atoms/CustomListItemButton';

export const selectedItemDefiner = (pathname: string, path: string) => {
  let result = false;
  const isEditPage = pathname.includes('edit');
  if (pathname === '/' && path === '/') {
    result = true;
  } else if (path !== '/') {
    result = (isEditPage && path === '/') || pathname.includes(path) || false;
  }
  return result;
};

const subItemsHeightCalculator = (index: number) => {
  let result = 0;
  switch (index) {
    case 1:
      result = 170;
      break;
    case 2:
      result = 0;
      break;
    case 3:
      result = 120;
      break;
    case 4:
      result = 0;
      break;
    default:
      break;
  }
  return result;
};

type ListItemsPropsType = {
  setOpenSandwichMenu: Dispatch<SetStateAction<boolean>>;
  list: sidebarItemType[];
};

export const ListItems: FC<ListItemsPropsType> = ({
  list,
  setOpenSandwichMenu,
}) => {
  const dispatch = useAppDispatch(),
    { pathname } = useLocation(),
    theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const resetSelectedEmployeeProfile = (path: string) => {
    !isMd && setOpenSandwichMenu(false);
    if (path.includes('profile')) {
      dispatch(setSelectedUserAction(null));
    }
    !isMd && setOpenSandwichMenu(false);
  };

  return (
    <>
      {list.map((item, index) => {
        const { title, icon, path, subPages, disabled, invisible } = item;

        const isSelected = selectedItemDefiner(pathname, path);

        if (invisible) return null;

        return (
          <Box key={index}>
            <CustomListItemButton
              to={path}
              sx={{
                px: 3.7,
                my: 1,
                mx: 'auto',
                transform: '300ms',
              }}
              onClick={() => resetSelectedEmployeeProfile(path)}
              disabled={disabled}
            >
              <ListItemIcon
                className="sidebar-itemIcon"
                sx={{
                  ...(!isMd && { minWidth: 35 }),
                  transition: '300ms',
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={title}
                primaryTypographyProps={{
                  color: ({ palette }) =>
                    isSelected ? palette.primary.main : 'white',
                  fontWeight: isSelected ? 'bold' : undefined,
                  fontSize: '18px',
                }}
              />
            </CustomListItemButton>
            {subPages && (
              <Box
                className={`sidebar-subList-wrapper sidebar-subList-wrapper-${
                  index + 1
                }`}
                sx={{
                  transformOrigin: 'top center',
                  transition: '300ms',
                  mx: 5,
                  height: 0,
                  opacity: 0,
                  transform: 'scaleY(0)',
                  ...(!isMd && {
                    opacity: 1,
                    transform: 'scaleY(1)',
                    height: subItemsHeightCalculator(index + 1),
                  }),
                }}
              >
                <ListSubItems
                  subItems={subPages}
                  setOpenSandwichMenu={setOpenSandwichMenu}
                />
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
};
