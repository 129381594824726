import type { FC, Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import {
  IconButton,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useTheme } from '@mui/system';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from './LanguageSwitcher';
import { BreadCrumbs } from './BreadCrumbs';
import { NotificationCenter } from './notification/NotificationCenter';
import {
  useWorkspacesControllerGetFolderByIdQuery,
  useWorkspacesControllerGetWorkspaceByIdQuery,
} from 'src/app/services/api.generated';

type NavbarPropsType = {
  setOpenSandwichMenu: Dispatch<SetStateAction<boolean>>;
};

export const Navbar: FC<NavbarPropsType> = ({ setOpenSandwichMenu }) => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('md'));
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const pathItems = pathname.replace(/^\/|\/$/g, '').split('/');
  const isInWorkspaces = pathItems[0] === 'workspaces';
  const isInNotifications = pathItems[0] === 'notifications';

  const { data: workspace } = useWorkspacesControllerGetWorkspaceByIdQuery(
    {
      id: pathItems[1],
    },
    { skip: !isInWorkspaces || pathItems.length < 2 }
  );

  const { data: folder } = useWorkspacesControllerGetFolderByIdQuery(
    {
      folderId: pathItems[2],
      workspaceId: workspace?.id as string,
    },
    { skip: pathItems.length < 3 || !workspace }
  );

  const correctPath = useMemo(() => {
    if (!pathItems?.[0]) return t('home');

    if (isInNotifications) {
      return t('notifications');
    }

    if (isInWorkspaces) {
      if (pathItems.length === 2) {
        return workspace?.name || '';
      }
      if (pathItems.length === 3) {
        return folder?.name || '';
      }
    }

    return t(pathItems[pathItems.length - 1]);
  }, [
    pathItems,
    t,
    isInNotifications,
    isInWorkspaces,
    workspace?.name,
    folder?.name,
  ]);

  return (
    <Toolbar sx={{ display: 'inline-block' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: { xs: 1, md: 2 }, pb: { xs: 1, sm: 0 } }}
      >
        <Stack direction="row" alignItems="center" columnGap={1}>
          {!isLg && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setOpenSandwichMenu((preState) => !preState)}
            >
              <MenuIcon color="primary" fontSize="large" />
            </IconButton>
          )}
          <Typography
            fontSize={{ xs: 25, md: 32 }}
            fontWeight={700}
            color="white"
            sx={{ textTransform: 'capitalize' }}
            textOverflow="ellipsis"
            noWrap
            overflow="hidden"
            maxWidth={{ xs: 150, sm: 380, md: 550 }}
          >
            {correctPath ? (
              correctPath
            ) : (
              <Skeleton
                sx={{ width: { xs: 100, sm: 150, md: 200, lg: 250 } }}
              />
            )}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" columnGap={2}>
          {!isInNotifications && <NotificationCenter />}
          <LanguageSwitcher />
        </Stack>
      </Stack>
      <BreadCrumbs />
    </Toolbar>
  );
};
