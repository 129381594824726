import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { DashboardActions } from 'src/components/organisms/dashboard/DashboardActions';
import { DashboardTasks } from 'src/components/organisms/dashboard/DashboardTasks';
import { DashboardUsage } from 'src/components/organisms/dashboard/DashboardUsage';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const boxTitle = (title: string) => (
  <Typography sx={{ mb: 2 }} fontSize={28} fontWeight={600}>
    {title}
  </Typography>
);

const Dashboard: FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid2 container justifyContent="space-between" gap={6} sx={{ mb: 6 }}>
        <Grid2 xs={12} md={7.2}>
          {boxTitle(t('quickAccess'))}
          <DashboardActions />
        </Grid2>
        <Grid2 xs={12} md={4}>
          {boxTitle(t('usage'))}
          <DashboardUsage />
        </Grid2>
      </Grid2>
      <Box>
        {boxTitle(t('lastModified'))}
        <DashboardTasks />
      </Box>
    </Box>
  );
};

export default Dashboard;
