import { FC } from 'react';
import { Paper } from '@mui/material';
import { useTasksControllerGetLastModifiedTasksQuery } from 'src/app/services/api.generated';
import { TasksTable } from '../task/taskTable/TasksTable';

export const DashboardTasks: FC = () => {
  const { data: tasks = [], isLoading } =
    useTasksControllerGetLastModifiedTasksQuery({
      numberOfTasks: 5,
    });

  return (
    <Paper
      elevation={0}
      sx={{
        px: { xs: 1.5, md: 2, lg: 3 },
        py: 3,
      }}
    >
      <TasksTable
        data={tasks}
        isLoading={isLoading}
        pagination={false}
        showToolbar={false}
        showCheckbox={false}
      />
    </Paper>
  );
};
