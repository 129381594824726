import { FC, useEffect, useState } from 'react';
import {
  ClickAwayListener,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const TableSearchInput: FC<TextFieldProps> = (props) => {
  const [click, setClick] = useState(false);
  const [hover, setHover] = useState(false);
  const [showFullSize, setShowFullSize] = useState(false);

  useEffect(() => {
    if (props.value) {
      setShowFullSize(true);
    } else {
      if (click) {
        setShowFullSize(true);
      } else {
        if (hover) {
          setShowFullSize(true);
        } else {
          setShowFullSize(false);
        }
      }
    }
  }, [click, hover, props.value]);

  const { t } = useTranslation();

  const clickInHandler = () => {
    if (props.disabled) return;
    setClick(true);
  };
  const clickOutHandler = () => {
    setClick(false);
  };
  const hoverInHandler = () => {
    if (props.disabled) return;
    setHover(true);
  };
  const hoverOutHandler = () => {
    setHover(false);
  };

  return (
    <ClickAwayListener onClickAway={clickOutHandler}>
      <TextField
        onMouseLeave={hoverOutHandler}
        onMouseEnter={hoverInHandler}
        sx={{
          transition: '150ms',
          width: showFullSize ? { xs: '100%', sm: 350 } : 130,
          '&>.MuiInputBase-root': {
            '&> fieldset': {
              border: showFullSize
                ? '1px solid #aaa !important'
                : 'none !important',
            },
          },
        }}
        size="small"
        onClick={clickInHandler}
        InputProps={{
          placeholder: t('search') || '',
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ opacity: props.disabled ? 0.5 : 1 }} />
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </ClickAwayListener>
  );
};
