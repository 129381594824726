import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type UploadNewFileSvgPropsType = {} & SvgIconProps;

const UploadNewFileSvg: FC<UploadNewFileSvgPropsType> = ({ ...svgProps }) => {
  return (
    <SvgIcon {...svgProps}>
      <path
        d="M17.7007 3.48492L16.0186 0H3.87372C3.20697 0 2.6665 0.540514 2.6665 1.20726V22.7927C2.6665 23.4594 3.20697 23.9999 3.87372 23.9999H18.1667C18.4311 23.9999 18.6754 23.9147 18.8743 23.7705H19.8918V4.24845L17.7007 3.48492Z"
        fill="#EAF6FF"
      />
      <path
        d="M19.8921 4.24879L19.3743 4.06836V4.99836V22.7931C19.3743 23.4598 18.8338 24.0003 18.167 24.0003H19.8096C20.4764 24.0003 21.0169 23.4598 21.0169 22.7931V4.99832L19.8921 4.24879Z"
        fill="#D8ECFE"
      />
      <path
        d="M18.1192 8.31902H5.56382C5.3638 8.31902 5.20166 8.15689 5.20166 7.95689C5.20166 7.75686 5.3638 7.59473 5.56382 7.59473H18.1191C18.3192 7.59473 18.4813 7.75686 18.4813 7.95689C18.4813 8.15689 18.3192 8.31902 18.1192 8.31902Z"
        fill="#82AEE3"
      />
      <path
        d="M18.1192 11.5387H5.56382C5.3638 11.5387 5.20166 11.3766 5.20166 11.1766C5.20166 10.9766 5.3638 10.8145 5.56382 10.8145H18.1191C18.3192 10.8145 18.4813 10.9766 18.4813 11.1766C18.4813 11.3766 18.3192 11.5387 18.1192 11.5387Z"
        fill="#82AEE3"
      />
      <path
        d="M18.1192 14.7585H5.56382C5.3638 14.7585 5.20166 14.5963 5.20166 14.3963C5.20166 14.1963 5.3638 14.0342 5.56382 14.0342H18.1191C18.3192 14.0342 18.4813 14.1963 18.4813 14.3963C18.4813 14.5963 18.3192 14.7585 18.1192 14.7585Z"
        fill="#82AEE3"
      />
      <path
        d="M18.1192 17.9772H5.56382C5.3638 17.9772 5.20166 17.8151 5.20166 17.6151C5.20166 17.4151 5.3638 17.2529 5.56382 17.2529H18.1191C18.3192 17.2529 18.4813 17.4151 18.4813 17.6151C18.4813 17.8151 18.3192 17.9772 18.1192 17.9772Z"
        fill="#82AEE3"
      />
      <path
        d="M17.2258 4.99798H21.0165L16.0186 0V3.79072C16.0186 4.45748 16.559 4.99798 17.2258 4.99798Z"
        fill="#B3DAFE"
      />
    </SvgIcon>
  );
};

export default UploadNewFileSvg;
