import type {
  FC,
  MouseEvent,
  ChangeEvent,
  SetStateAction,
  Dispatch,
} from 'react';
import {
  TablePagination,
  Pagination,
  PaginationItem,
  Stack,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { useTranslation } from 'react-i18next';

const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: TablePaginationActionsProps) => {
  const onChange = (event: ChangeEvent<unknown>, page: number) => {
    onPageChange(null, page - 1);
  };

  return (
    <Pagination
      sx={{ p: 0 }}
      color="primary"
      count={Math.ceil(count / rowsPerPage)}
      page={page + 1}
      shape="rounded"
      renderItem={(item) => (
        <PaginationItem
          components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
          {...item}
        />
      )}
      onChange={onChange}
    />
  );
};

type CustomTablePaginationPropsType = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  handleChangePage: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  rowsLength: number;
  colSpan: number;
};

export const CustomTablePagination: FC<CustomTablePaginationPropsType> = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  handleChangePage,
  rowsLength,
  colSpan,
}) => {
  const { t } = useTranslation();
  const handleChangeRowsPerPage = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
  };

  return (
    <Stack
      direction="row"
      sx={{ minWidth: '100%', mt: 2 }}
      justifyContent="center"
    >
      <TablePagination
        component="div"
        sx={{
          '&> .MuiToolbar-root': {
            flexDirection: 'column-reverse',
            alignItems: 'center',
            justifyContent: 'center',
            px: 1,
            py: 2,
            '&> .MuiTablePagination-displayedRows': {
              p: 0,
              mb: 0,
            },
          },
        }}
        rowsPerPageOptions={[rowsPerPage]}
        labelDisplayedRows={({ from, to, count, page }) =>
          `${t('showing')} ${from} ${t('to')} ${to} ${t('of')} ${count} ${t(
            'entries'
          )}`
        }
        colSpan={colSpan}
        count={rowsLength}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Stack>
  );
};
