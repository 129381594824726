import { createSlice } from '@reduxjs/toolkit';
import { CompanyRo } from '../services/api.generated';

type companySliceType = {
  selectedCompany: CompanyRo | null;
};

const initialState: companySliceType = {
  selectedCompany: null,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setSelectedCompany: (
      state,
      { payload }: { payload: companySliceType['selectedCompany'] }
    ) => {
      state.selectedCompany = payload;
    },
  },
});

export const { setSelectedCompany: setSelectedCompanyAction } =
  companySlice.actions;

export default companySlice.reducer;
