import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import theme from 'src/configs/theme';
import { useTasksControllerGetLastModifiedTasksQuery } from 'src/app/services/api.generated';
import ResumeImageSvg from 'src/components/atoms/svg/ResumeImageSvg';
import UploadNewFileSvg from 'src/components/atoms/svg/UploadNewFileSvg';

type taskBoxType = {
  title: string;
  txt: string;
  icon: any;
  href: string;
  isDisable?: boolean;
  onClick?: () => any;
  btnVariant?: ButtonProps['variant'];
};

const transition = `${
  theme.transitions.duration.shorter +
  'ms ' +
  theme.transitions.easing.easeInOut
}`;

const ActionRow: FC<taskBoxType> = (props) => {
  const { title, txt, icon, href, isDisable, onClick } = props;
  return (
    <Link
      to={isDisable ? '' : href}
      style={{ cursor: isDisable ? 'default' : 'pointer' }}
      onClick={onClick}
    >
      <Button
        disabled={isDisable}
        fullWidth
        sx={{
          borderRadius: '8px',
          height: '100%',
          minHeight: '200px',
          backgroundColor: 'customGrey.main',
          p: { xs: 2, md: 2 },
          display: 'inline-block',
          textAlign: 'center',
          '&:hover': {
            transition,
            boxShadow: theme.shadows[7],
            backgroundColor: 'customGrey.main',
            '& .dashboardIcon': {
              transform: 'scale(1.3)',
            },
            '& .title': {
              transform: 'translateY(110%)',
            },
            '& .text': {
              opacity: 0,
            },
          },
        }}
      >
        <Box
          sx={{
            transformOrigin: 'top center',
            transition,
          }}
          className="dashboardIcon"
        >
          {icon}
        </Box>
        <Typography
          color={isDisable ? 'grey' : 'white'}
          fontSize={20}
          fontWeight={800}
          sx={{
            transformOrigin: 'top center',
            transition,
            my: 1.7,
          }}
          className="title"
        >
          {title}
        </Typography>
        <Typography
          color={isDisable ? 'grey' : 'white'}
          align="center"
          lineHeight="17px"
          fontSize={14}
          sx={{
            transformOrigin: 'top center',
            transition,
          }}
          className="text"
        >
          {txt}
        </Typography>
      </Button>
    </Link>
  );
};

const ActionButton: FC<taskBoxType> = (props) => {
  const { title, txt, icon, href, isDisable, onClick, btnVariant } = props;
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        {icon}
        <Stack gap={1}>
          <Typography fontSize={20} fontWeight={800}>
            {title}
          </Typography>
          <Typography fontSize={14}>{txt}</Typography>
        </Stack>
      </Stack>
      <Link
        to={isDisable ? '' : href}
        style={{
          cursor: isDisable ? 'default' : 'pointer',
          textDecoration: 'none',
        }}
        onClick={onClick}
      >
        <Button
          disabled={isDisable}
          variant={btnVariant || 'contained'}
          sx={{ width: 130 }}
        >
          {title}
        </Button>
      </Link>
    </Stack>
  );
};

export const DashboardActions: FC = () => {
  const { t } = useTranslation();

  const { data: tasks = [] } = useTasksControllerGetLastModifiedTasksQuery({
    numberOfTasks: 1,
  });

  const lastTask = useMemo(() => (tasks.length > 0 ? tasks[0] : null), [tasks]);

  const taskArray: taskBoxType[] = [
    {
      title: t('resumeTask'),
      txt: t('continueYourLatestTask'),
      icon: <ResumeImageSvg isDisable={!lastTask?.id} sx={{ fontSize: 50 }} />,
      href: '/edit/' + lastTask?.id,
      isDisable: !lastTask?.id,
    },
    {
      title: t('createTask'),
      txt: t('quicklyUploadAFileAndCreateANewTask'),
      icon: <UploadNewFileSvg sx={{ fontSize: 50 }} />,
      href: '/createTasks',
      btnVariant: 'outlined',
    },
  ];

  return (
    <>
      <Grid
        container
        justifyContent="space-around"
        gap={{ xs: 3, md: 6 }}
        sx={{ display: { xs: 'flex', md: 'none' } }}
      >
        {taskArray.map((item, index) => (
          <Grid item xs={12} md key={index}>
            <ActionRow {...item} />
          </Grid>
        ))}
      </Grid>
      <Paper
        component={Stack}
        justifyContent="center"
        sx={{ height: 226, px: 4, display: { xs: 'none', md: 'flex' } }}
      >
        <Stack gap={3} divider={<Divider flexItem orientation="horizontal" />}>
          {taskArray.map((item, index) => (
            <ActionButton key={index} {...item} />
          ))}
        </Stack>
      </Paper>
    </>
  );
};
