import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type TickSvgPropsType = {} & SvgIconProps;

const TickSvg: FC<TickSvgPropsType> = ({ ...svgProps }) => {
  return (
    <SvgIcon {...svgProps}>
      <path d="M 23.648438 0.539062 C 23.179688 0.0703125 22.421875 0.0703125 21.953125 0.539062 L 7.574219 14.914062 L 2.046875 9.390625 C 1.578125 8.921875 0.820312 8.921875 0.351562 9.390625 C -0.117188 9.859375 -0.117188 10.617188 0.351562 11.085938 L 6.726562 17.460938 C 7.195312 17.929688 7.953125 17.929688 8.421875 17.460938 L 23.648438 2.234375 C 24.117188 1.765625 24.117188 1.007812 23.648438 0.539062 Z M 23.648438 0.539062" />
    </SvgIcon>
  );
};

export default TickSvg;
