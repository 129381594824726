import { createSlice } from '@reduxjs/toolkit';

type notificationSliceType = {
  selectedNotif: string | null;
};

const initialState: notificationSliceType = {
  selectedNotif: null,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setSelectedNotification: (
      state,
      { payload }: { payload: notificationSliceType['selectedNotif'] }
    ) => {
      state.selectedNotif = payload;
    },
  },
});

export const { setSelectedNotification: setSelectedNotificationAction } =
  notificationSlice.actions;

export default notificationSlice.reducer;
