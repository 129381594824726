import { MouseEvent, FC, Dispatch, SetStateAction, ChangeEvent } from 'react';
import { useMemo } from 'react';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';
import { TaskRo } from 'src/app/services/api.generated';
import { useLocation } from 'react-router';

type Order = 'asc' | 'desc';

type HeadCell = {
  disablePadding: boolean;
  id: keyof TaskRo;
  label: string;
  numeric: boolean;
};

type TasksTableHeaderPropsType = {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof TaskRo) => void;
  order: Order;
  orderBy: string;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  rows: TaskRo[];
  showCheckbox: boolean;
};

export const TasksTableHeader: FC<TasksTableHeaderPropsType> = ({
  onRequestSort,
  order,
  orderBy,
  selected,
  setSelected,
  rows,
  showCheckbox,
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const createSortHandler =
    (property: keyof TaskRo) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('name'),
    },
    {
      id: 'creator',
      numeric: false,
      disablePadding: false,
      label: t('creator'),
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: t('createdAt'),
    },
    {
      id: 'state',
      numeric: false,
      disablePadding: false,
      label: t('status'),
    },
    {
      id: 'outputState',
      numeric: false,
      disablePadding: false,
      label: t('Generated'),
    },
  ];

  const selectedRowsCount = useMemo(() => selected.length, [selected.length]);
  const totalRowsCount = useMemo(() => rows.length, [rows.length]);

  const checkBoxOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map(({ id }) => id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  return (
    <TableHead>
      <TableRow
        sx={
          pathname === '/'
            ? {
                position: 'relative',
                '&::after': {
                  width: '100%',
                  height: '1px',
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    'linear-gradient(90deg, rgba(53,58,66,0) 2.5%, rgba(255,255,255,1) 50%, rgba(53,58,66,0) 97.5%)',
                },
              }
            : undefined
        }
      >
        {showCheckbox && (
          <TableCell padding="checkbox" sx={{ py: 2 }}>
            <Checkbox
              color="primary"
              indeterminate={
                selectedRowsCount > 0 && selectedRowsCount < totalRowsCount
              }
              checked={
                totalRowsCount > 0 && selectedRowsCount === totalRowsCount
              }
              onChange={checkBoxOnChange}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell, index) => (
          <TableCell
            sx={{ py: 2 }}
            align={index === 0 ? 'left' : 'center'}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
