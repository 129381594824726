import { createSlice } from '@reduxjs/toolkit';
import { UserRo } from '../services/api.generated';

type userSliceType = {
  selectedUser: UserRo | null;
};

const initialState: userSliceType = {
  selectedUser: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSelectedUser: (
      state,
      { payload }: { payload: userSliceType['selectedUser'] }
    ) => {
      state.selectedUser = payload;
    },
  },
});

export const { setSelectedUser: setSelectedUserAction } = userSlice.actions;

export default userSlice.reducer;
