import { FC, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { DashboardTemplate } from 'src/components/templates/DashboardTemplate';
import { EditPageHeader } from 'src/components/organisms/editPage/header/EditPageHeader';
import { PageLoading } from 'src/components/atoms/PageLoading';
import Dashboard from 'src/pages/dashboard';
import NotFound from 'src/pages/notfound';
import Login from 'src/pages/login/login';
import { ForgotPassword } from 'src/pages/login/forgotPassword';

const Workspaces = lazy(() => import('src/pages/workspace/workspaces'));
const WorkspacesDetails = lazy(
  () => import('src/pages/workspace/workspaceDetails')
);
const CreateTasks = lazy(() => import('src/pages/createTasks'));
const Profile = lazy(() => import('src/pages/profile'));
const Users = lazy(() => import('src/pages/users'));
const Edit = lazy(() => import('src/pages/edit'));
const WorkspaceFolder = lazy(
  () => import('src/pages/workspace/workspaceFolder')
);
const Dictionary = lazy(() => import('src/pages/dictionary'));
const Notifications = lazy(() => import('src/pages/notifications'));
const Companies = lazy(() => import('src/pages/companies'));

type getPageType = (
  PageComponent: FC,
  TemplateComponent?: FC,
  templateProps?: any
) => JSX.Element;

const getPage: getPageType = (
  PageComponent,
  TemplateComponent = DashboardTemplate,
  templateProps
) => (
  <TemplateComponent {...templateProps}>
    <Suspense fallback={<PageLoading />}>
      <PageComponent />
    </Suspense>
  </TemplateComponent>
);

const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword/*" element={<ForgotPassword />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/"
            element={<DashboardTemplate children={<Dashboard />} />}
          />
          <Route path="/createTasks" element={getPage(CreateTasks)} />
          <Route
            path="/edit/:id"
            element={getPage(Edit, DashboardTemplate, {
              navbarComponent: <EditPageHeader />,
            })}
          />
          <Route path="/profile" element={getPage(Profile)} />
          <Route path="/users" element={getPage(Users)} />
          <Route path="/workspaces" element={getPage(Workspaces)} />
          <Route
            path="/workspaces/:workspaceId"
            element={getPage(WorkspacesDetails)}
          />
          <Route
            path="/workspaces/:workspaceId/:folderId"
            element={getPage(WorkspaceFolder)}
          />
          <Route path="/dictionary" element={getPage(Dictionary)} />
          <Route path="/notifications" element={getPage(Notifications)} />
          <Route path="/companies" element={getPage(Companies)} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
